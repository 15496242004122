<!-- <i class="fa fa-bell-o" aria-hidden="true"></i> -->

<div
  ngbDropdown
  [autoClose]="'outside'"
  [ngClass]="{ 'unread-notification': unreadNotification }"
  (openChange)="messageChecked($event)"
  class="d-inline-block bell"
  *ngIf="messages.length > 0"
>
  <i class="fa fa-bell-o" aria-hidden="true" ngbDropdownToggle></i>
  <div ngbDropdownMenu aria-labelledby="dropdownForm1">
    <ng-container *ngFor="let message of messages; let i = index">
      <div ngbDropdownItem>
        <div
          [ngClass]="{
            'unread-notification unread-row-icon': !alreadyReadIds.includes(message.id)
          }"
          [ngSwitch]="message.level"
        >
          <i *ngSwitchCase="1" class="fa fa-question" aria-hidden="true"></i>
          <i *ngSwitchCase="2" class="fa fa-exclamation" aria-hidden="true"></i>
          <i *ngSwitchCase="3" class="fa fa-info" aria-hidden="true"></i>
          <i *ngSwitchDefault class="fa fa-info" aria-hidden="true"></i>
        </div>
        <!-- メールステータス更新通知の場合は文末を「...」で省略する -->
        <div [ngClass]="{'omit_end_of_sentence': message.level == 3}">{{ message.message }}
          <a
            *ngIf="message.url"
            [href]="message.url"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="fa fa-external-link" aria-hidden="true"></i
          ></a>
        </div>
      </div>
      <div *ngIf="i !== messages.length - 1" class="dropdown-divider"></div>
    </ng-container>
  </div>
</div>
