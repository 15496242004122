<div *ngIf="!isLogin()">
  <app-before-login-logo></app-before-login-logo>
</div>
<div [ngClass]="isLogin() ? 'after-login-background' : 'before-login-background'">
  <div class="container" style="margin-top:130px;">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="rule__text-area" [ngClass]="isLogin() ? 'after-login' : 'before-login'">
          <h2>Qoala利用規約</h2>
          <div class="rule__text-area__border"></div>
          <p>
            <span class="heading">第１条（規約）<br /></span>
            １.
            本規約は、クラウド型サービス「Qoala（コアラ）」（サービスの名称・内容が変更された場合の変更後の名称・内容も含みます。以下「本サービス」といいます）の利用に関し、利用者（以下「甲」といいます）と、本サービスの開発者、及び提供者であるリフ株式会社（以下「乙」といいます）の間に適用されます。甲は、本サービスの利用にあたり、本規約に同意したものとみなされます。<br />
            ２.
            本サービスは複数の個別サービスで構成されます。一部の個別サービスの利用に際しては、本規約のほか、個別サービスごとに別途利用規約あるいは乙のウェブサイト上において、本サービスの申込みおよび利用に関する条件（以下「個別規約等」といいます。）を掲載する場合があります。その場合は、個別規約等は本規約の一部として適用されます。なお、「乙のウェブサイト」とは、そのドメインが「qoala.jp」もしくは「qoala.jp」のサブドメインである、乙が運営するウェブサイト（理由の如何を問わず乙のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味し、本サービスに関するアプリケーションがある場合は、当該アプリケーションも含むものとします（以下、本規約において同じ）。<br />
            ３.
            個別規約等が用意されている場合は、個別規約等が本規約に優先して適用され、個別規約等に定めがないものについては本規約が適用されます。<br /><br />

            <span class="heading">第２条（規約の変更）<br /></span>
            １.
            乙はいつでも、甲の事前の承諾を得ることなく、本規約の内容を変更することができます。<br />
            ２.
            乙が本規約の内容を変更し、その変更内容を甲に通知した場合、通知において指定された期日以降（ただし、通知において期日を指定しない場合には、乙が通知を発した当日を期日とします。）に、本サービスを利用した場合、変更後の本規約に同意したものとみなし、変更後の本規約が適用されます。甲が通知において指定された期日内に解約の手続きをとらなかった場合には、変更後の本規約に同意したものとみなされます。<br />
            ３.
            前２項の規定にかかわらず、法令上、甲の同意やあらかじめの通知等が必要となるような内容の変更の場合は、乙の所定の方法で甲の同意を得、またはあらかじめの通知等を行うものとします。<br /><br />

            <span class="heading">第３条（連絡・通知）<br /></span>
            １.
            乙は、本サービスに関連して甲に通知をする場合には、本サイトに掲示する方法または登録された甲指定の電子メールアドレス・住所に宛てて電子メール・文書を送信する方法など、乙が適当と判断する方法で実施します。<br />
            ２.
            前項に定める方法により行われた通知は、通知が甲に到達したか否かにかかわらず、前者の場合には通知内容が本サイトに掲示された時点に、後者の場合は乙が電子メール・文書を発信した時点で、それぞれその効力を生じるものとします。<br />
            ３.
            本サービスに関する問い合わせその他甲から乙に対する連絡または通知は、乙の定める方法で行うものとします。<br /><br />

            <span class="heading">第４条（申込・登録）<br /></span>
            １.
            甲において本サービスの利用手続について正当な権限を有する本サービスの利用を希望する者（以下「登録希望者」といいます）は、本規約を遵守することに同意し、かつ乙の定める一定の情報（以下「登録情報」といいます。）を乙の定める方法で乙に提供することにより、乙に対し、本サービスの利用の登録を申請することができます。<br />
            ２.
            登録の申請は必ず本サービスを利用する個人又は法人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を乙に提供しなければなりません。登録情報の内容に虚偽、誤記又は記載漏れがあったことにより甲に損害が生じた場合であっても、乙は一切責任を負いません。<br />
            ３.
            甲は、甲における本サービスの利用について正当な権限を有する者（以下「登録ユーザー」といいます）を指定して登録を申請することができます。<br />
            ４.
            乙は、乙の基準に従って、登録希望者の登録の可否を判断し、乙が登録を認める場合にはその旨を登録希望者に通知し、この通知により登録希望者の甲としての登録は完了したものとします。<br />
            ５.
            前項に定める登録の完了時に、本規約の諸規定に従った本サービスの利用契約が甲と乙との間に成立し、甲は本サービスを乙の定める方法で利用することができるようになります。<br /><br />

            <span class="heading">第５条（登録の拒否）<br /></span>
            乙は、前条１項に基づき、登録の申請を行った者が次の各号のいずれかに該当する場合、当該サービス利用者の登録（再登録を含みます）を拒否することがあります。なお、乙が本条により登録を拒否した場合、乙はその理由について一切の開示義務を負いません。<br />
            ⑴　本規約に違反するおそれがあると乙が判断した場合<br />
            ⑵　乙に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合<br />
            ⑶　反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると乙が判断した場合<br />
            ⑷　登録申請者が過去に乙との契約に違反した者またはその関係者と乙が判断した場合<br />
            ⑸　登録申請者が第１３条に定める措置を受けたことがある場合<br />
            ⑹　その他、乙が登録を適当でないと判断した場合<br /><br />

            <span class="heading">第６条（登録情報の変更）<br /></span>
            甲の登録事項に変更があった場合、甲は乙に対し、乙の定める方法により当該変更事項を遅滞なく通知し、乙から要求された資料を提出するものとします。<br /><br />

            <span class="heading">第７条（パスワード及びユーザーIDの管理）<br /></span>
            １.
            甲は、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。<br />
            ２.
            乙は、当該ユーザーIDとパスワードの一致を確認した場合、ユーザーIDを保有するものとして登録されたユーザーが本サービスを利用したものとみなします。<br />
            ３.
            パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は甲が負うものとし、乙は一切の責任を負わないものとします。<br />
            ４.
            甲は、パスワードが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を乙に通知するとともに、乙からの指示に従うものとします。<br /><br />

            <span class="heading">第８条（料金及び支払方法）<br /></span>
            １．乙は、甲における本サービス利用料金を、乙が乙のウェブサイト上で表示又は見積書によって提示する料金、その他各種手数料を、乙が乙のウェブサイト上又は見積書で指定する支払方法にて支払うものとします。<br />
            ２．甲が前項の利用料金の支払いを遅滞した場合、甲は乙に対し、年１４．６％（1年を３６５日とした計算）の割合による遅延損害金を支払うものとします。<br />
            ３．本サービスを利用するために必要となる通信費（本ソフトウェアのダウンロードおよび利用のために発生する通信費を含みます。）、および通信機器等は、甲の負担と責任により準備するものとします。但し、甲の使用する通信機器等において、本サイトおよび本ソフトウェアが正常に動作することを保証するものではありません。<br /><br />

            <span class="heading">第９条（サービス利用の周知等）<br /></span>
            １.
            甲における本サービスの利用手続きや管理について正当な権限を有する担当者（以下「管理者等」といいます）は、本サービスの利用を開始する前に、登録ユーザーに対し、本サービスの内容、機能、使い方、禁止事項等を周知させるものとします。<br />
            ２.
            甲は、甲が必要と判断する場合において、本サービスの利用を開始する前に、甲にメールを配信する可能性のある取引先等及びその従業員等に対し、本サービスを利用すること、及び、登録ユーザー宛に届いたメール内容のうち、案件や人材情報に関わる、単価、勤務地、最寄り駅、使用言語やツール、業務履歴など、業務やスキルに関するキーワード（以下「案件・人材情報のキーワード」といいます）を、送信元の個人や会社が特定できない形で集計処理し、統計データやレポート等として活用することがある旨を、メール等で周知させるものとします。<br />
            ３.
            本サービスの利用開始後、甲にメールを配信する可能性のある新たな取引先等を覚知したときも、前項と同様の措置を行うものとします。<br />
            ４.
            甲は、本サービスがメール内容における案件・人材情報のキーワードを取り扱うものであることを十分に理解し、本サービスの利用にあたっては乙が指定した利用方法を遵守するものとします。<br />
            ５.
            乙は、前４項の周知等を行わなかったことにより甲に損害が生じたとしても、当該損害について乙に故意または重過失が無い限り一切の責任を負いません。<br /><br />

            <span class="heading">第１０条（禁止事項）<br /></span>
            甲（管理者等及び登録ユーザーを含みます）は、本サービスの利用にあたり、次の各号に該当する行為をしてはなりません。<br />
            ⑴　法令に違反する行為または犯罪行為に関連する行為<br />
            ⑵　乙または本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為<br />
            ⑶　公序良俗に反する行為<br />
            ⑷　乙または本サービスの他の利用者またはその他の第三者の知的財産権（著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権のことをいい、それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。以下、同じ）、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為<br />
            ⑸　本サービスを通じ、乙または本サービスの他の利用者に対し、以下に該当する情報を送信すること<br />
            ・　過度に暴力的または残虐な表現を含む情報<br />
            ・　コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報<br />
            ・　乙、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報<br />
            ・　わいせつな表現を含む情報<br />
            ・　差別を助長する表現を含む情報<br />
            ・　自殺、自傷行為を助長する表現を含む情報<br />
            ・　薬物の不適切な利用を助長する表現を含む情報<br />
            ・　反社会的な表現を含む情報<br />
            ・　チェーンメール等の第三者への情報の拡散を求める情報<br />
            ・　他人に不快感を与える表現を含む情報<br />
            ・　面識のない異性との出会いを目的とした情報<br />
            ⑹　本サービスのネットワークまたはシステム等に過度な負荷をかける行為<br />
            ⑺　本サービスの運営を妨害するおそれのある行為<br />
            ⑻　乙のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為<br />
            ⑼　本サービスに対する逆アセンブル、逆コンパイル、リバースエンジニアリング等、またはその他本サービスのソースコード、構造、アイディア等を解析するような行為<br />
            ⑽　第三者になりすます行為<br />
            ⑾　本サービスの他の利用者のIDまたはパスワードを利用する行為<br />
            ⑿　乙または本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為<br />
            ⒀　本規約の各条項に違反する行為<br />
            ⒁　その他、乙が不適切と判断する行為<br />
            １.
            乙は、甲が前項各号のいずれかに該当したときは、何らの通知や催告をすることなく利用契約を解除することができるものとします。この場合甲が乙に対して支払った料金はその理由の如何を問わず返還されないものとします。<br />
            ２.
            乙は、前項に基づき乙が行った措置に基づき甲に生じた損害について一切の責任を負いません。<br /><br />

            <span class="heading">第１１条（本サービスの提供の停止・中断・終了）<br /></span>
            １.
            乙は、乙の判断により本サービスの全部又は一部の提供・運営を終了することができるものとします。乙は、乙の判断により本サービスの全部又は一部の提供・運営を終了する場合、乙が適当と判断する方法で甲にその旨通知いたします。ただし、緊急の場合は甲への通知を行わない場合があります。<br />
            ２.
            乙は、以下各号の事由が生じた場合には、甲（管理者等及び登録ユーザーを含みます。本条につき同じ。）に事前に通知することなく、本サービスの一部又は全部を一時的に中断することができるものとします。<br />
            ⑴　本サービス用のハード・ソフト・通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合<br />
            ⑵　アクセス過多、その他予期せぬ要因でシステムに負荷が集中した場合<br />
            ⑶　乙のセキュリティを確保する必要が生じた場合<br />
            ⑷　ハッキングその他の方法により乙の保有する情報が流出等した場合<br />
            ⑸　電気通信事業者の役務が提供されない場合<br />
            ⑹　天災等の不可抗力により本サービスの提供が困難な場合<br />
            ⑺　火災、停電、その他の不慮の事故又は戦争、紛争、動乱、暴動、労働争議等により本サービスの提供が困難な場合<br />
            ⑻　法令又はこれらに基づく措置により本サービスの運営が不能となった場合<br />
            ⑼　その他前各号に準じ乙が必要と判断した場合<br />
            ３.
            乙は、本条に基づき乙が行った措置により甲に生じた損害について、一切の責任を負いません。<br /><br />

            <span class="heading">第１２条（権利の帰属）<br /></span>
            １.
            本サービスに関する所有権及び知的財産権は全て乙又は乙にライセンスを許諾している者に帰属し、本規約に基づく本サービスの利用許諾は、本サービスに関する乙の知的財産権の使用許諾を意味するものではありません。<br />
            ２.
            甲が本サービスを通じて送受信するメール内容のデータは甲に帰属し、乙はその内容を閲覧することはできません。<br />
            ３.
            前項にかかわらず、送受信されたメールについて、本サービスがメールを誤って分類した場合またはメールの記載内容を不正確に抽出した場合につき、登録ユーザーが書面にて乙に報告した場合に限り、乙のシステム開発責任者は、サービス機能の改善と向上のため、当該メールの内容を閲覧することができます。<br />
            ４.
            甲は、いかなる理由によっても乙又は乙にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしないものとします。<br />
            ５.
            乙は、本サービスのデータベースに蓄積された案件・人材情報のキーワードのデータを、送信元の個人や会社、及び受信した登録ユーザーの個人情報を特定できない形で集計、解析し、統計データや分析レポートとして活用（複製、複写、改変、第三者への再許諾その他のあらゆる活用を含みます。）することができるものとします。<br /><br />

            <span class="heading">第１３条（登録抹消等）<br /></span>
            １.
            乙は、甲（管理者等及び登録ユーザーを含みます）が次の各号のいずれかに該当する場合、事前に通知または催告をすることなく、入力データ（登録ユーザーが本サービスを利用して入力する文章や単語などのテキストデータのことをいいます）を削除し、当該登録ユーザーの本サービスの利用を一時的に停止または登録ユーザーとしての登録を抹消し、もしくは、本契約を解除することができるものとします。<br />
            ⑴ 甲が本サービス上の取引によって生じた債務の支払いを怠った場合<br />
            ⑵ 本規約の各条項に違反した場合<br />
            ⑶　乙に提供された登録情報に虚偽の事実があった場合<br />
            ⑷　支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始若しくはこれらに類する手続の開始の申立てがあった場合<br />
            ⑸　乙の問合せその他の回答を求める連絡に対して１４日間以上応答がない場合<br />
            ⑹　本サービスの利用に際して、過去に本サービス利用停止又はアカウント削除等の措置を受けたことがある又は現在も受けている場合<br />
            ⑺　反社会的勢力等であるか、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等、反社会的勢力等との何らかの交流若しくは関与を行っていると乙が判断した場合<br />
            ⑻　本サービスの運営・保守管理上必要であると乙が判断した場合<br />
            ⑼　その他前各号に類する事由があると乙が判断した場合<br />
            ２.
            甲は、登録抹消後も、乙及びその他の第三者に対する利用契約上の一切の義務及び債務（損害賠償債務を含みますが、これに限りません。）を免れるものではありません。<br />
            ３.
            前項各号のいずれかに該当した場合、甲は、乙に対して負っている債務の一切について当然に期限の利益を失い、直ちに全ての債務の支払を行わなければなりません。<br />
            ４.
            乙は、本条に基づき乙が行った行為により甲に生じた損害について一切の責任を負わず、甲の登録抹消後も、甲が乙に提供した情報を保有・利用することができるものとします。<br /><br />

            <span class="heading">第１４条（解約）<br /></span>
            １.
            甲が本サービスの解約を希望する場合は、乙所定の方法で解約希望日の１か月前までに乙に通知することにより、本サービスを解約することができるものとします。本サービスを解約した場合、解約の時点から本サービスを利用することができなくなります。<br />
            ２.
            本条による解約をする場合、他方に対して負担する金銭債務があるときは、解約をもって当然に期限の利益を失い、直ちに当該債務の履行を行わなければなりません。<br />
            ３.
            解約後に再度本サービスを利用する場合は、再度登録手続を行う必要があります。再度の登録手続を行った場合、解約前のデータは引き継がれない場合があります。<br />
            ４.
            本条による解約をする場合、解約により他方当事者に何らかの損害が生じたとしても、その損害を賠償する責任を負わないものとします。<br /><br />

            <span class="heading">第１５条（保証の否認）<br /></span>
            １.
            乙は、本サービスが甲および登録ユーザーの特定の目的に適合すること、業務の効率化営業等の成果が得られること、期待する機能・商品的価値・正確性・有用性・完全性を有すること、及び不具合が生じないことについて、何ら保証するものではありません。<br />
            ２.
            甲は、本サービスを利用することが、甲に適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、乙は、甲による本サービスの利用が、甲に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。<br />
            ３.
            乙は、本サービスを円滑に提供するための合理的な努力を行い、サービスの中断があった場合に合理的時間内において本サービスを復旧する努力を行いますが、安定したシステムの永続的提供、運営を保証するものではありません。<br />
            ４.
            乙のウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから乙のウェブサイトへのリンクが提供されている場合でも、乙は、乙のウェブサイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。<br /><br />

            <span class="heading">第１６条（不可抗力）<br /></span>
            １.
            乙は、通常講ずるべき対策では防止できないウィルス被害、ハッキング被害、停電被害、サーバ故障、回線障害、および天変地異による被害、その他乙の責によらない不可抗力に基づく事由（以下「不可抗力」といいます。）による損害が甲に生じた場合でも、一切の責任を負わないものとします。<br />
            ２.
            乙は、不可抗力に起因して本サービスにおいて管理または提供されるデータが消去・変更されないことを保証しません。<br /><br />

            <span class="heading">第１７条（甲と第三者との間の紛争）<br /></span>
            本サービスの利用に関連して発生した甲（管理者及び登録ユーザーを含みます。以下、本条につき同じ）と第三者との間の紛争は、甲及び登録ユーザーが自己の費用および責任で解決するものとし、乙に何らの迷惑または損害を与えないものとします。甲と第三者との間の紛争に関連して乙に損害が発生した場合、甲は乙に発生した一切の損害（弁護士費用等を含む）を賠償するものとします。<br /><br />

            <span class="heading">第１８条（紛争処理及び損害賠償）<br /></span>
            １.
            甲（管理者及び登録ユーザーを含みます。以下、本条につき同じ）は自己の責任において本サービスを利用するものとし、乙は、本規約に違反したことによって生じた甲の損害については、一切の責任を負いません。<br />
            ２.
            甲は、本規約に違反することにより、又は本サービスの利用に関連して乙に損害を与えた場合、乙に対しその全ての損害（弁護士等専門家費用及び乙において対応に要した人件費相当額を含みます。）を賠償しなければなりません。<br />
            ３.
            本サービスの利用に関連して甲が被った損害について、乙が債務不履行責任又は不法行為責任を負う場合には、甲に現実に発生した直接かつ通常の損害に限り、これを賠償する責任を負うものとし、付随的損害、間接損害、特別損害（損害の発生を予見し又は予見し得た場合を含みます。）、将来の損害及び逸失利益にかかる損害を含まないものとし、過去１２か月間に乙が甲から現実に受領した本サービスの料金の金額の範囲内とします。<br /><br />

            <span class="heading">第１９条（利用者情報の取扱い）<br /></span>
            乙は、乙が保有する甲の管理者等及び登録ユーザーにかかる利用者情報（個人情報保護法に定義する個人情報のほか、甲が本サービスの利用を申し込む時に乙に提供する情報、サービス利用者によるログ情報、本サービスのシステムやデータベースに保存、蓄積される情報等をいいます）については、乙が別途定めるプライバシーポリシーに基づいて適正に取り扱うものとし、甲はこれに同意するものとします。<br /><br />

            <span class="heading">第２０条（サービス利用上の地位の譲渡等）<br /></span>
            １.
            甲は、書面による事前の承諾なく、本規約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。<br />
            ２.
            乙が本サービスにかかる事業を第三者に譲渡した場合、乙は、当該事業譲渡に伴い、本規約上の地位、本規約に基づく権利及び義務並びに乙の保有する甲（管理者等及び登録ユーザーを含みます）に関する情報を当該事業譲渡の譲受人に譲渡することができるものとし、甲は、かかる譲渡につき本項によって予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。<br /><br />

            <span class="heading">第２１条（分離可能性）<br /></span>
            １.
            本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、甲及び乙は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。<br />
            ２.
            本規約のいずれかの条項又はその一部が、あるユーザーとの関係で無効又は執行不能と判断された場合であっても、他のユーザーとの関係における有効性等には影響を及ぼさないものとします。<br /><br />

            <span class="heading">第２２条（存続規定）<br /></span>
            第7条第３項、第８条（未払がある場合）、第９条第５項、第１０条第２、３項、第１１条第３項、第１２条、第１３条第２、３、４項、第１４条第２、３、４項、並びに第１５条から第２４条までの規定及び契約終了後も存続すると合理的に解釈することができる規定については、利用契約の終了後も有効に存続するものとします<br /><br />

            <span class="heading">第２３条（準拠法及び管轄裁判所）<br /></span>
            １. 本規約の準拠法は日本法とします。<br />
            ２.
            本規約に起因しまたは関連する一切の紛争については、訴額に応じ、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。<br /><br />

            <span class="heading">第２４条（協議解決）<br /></span>
            甲及び乙は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします<br /><br />

            附則<br /><br />

            平成３１年１月１日　制定・施行
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>