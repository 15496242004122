/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./before-login-logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/component/theme-switcher/theme-switcher.component.ngfactory";
import * as i3 from "../../shared/component/theme-switcher/theme-switcher.component";
import * as i4 from "../../shared/service/account.service";
import * as i5 from "./before-login-logo.component";
var styles_BeforeLoginLogoComponent = [i0.styles];
var RenderType_BeforeLoginLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BeforeLoginLogoComponent, data: {} });
export { RenderType_BeforeLoginLogoComponent as RenderType_BeforeLoginLogoComponent };
export function View_BeforeLoginLogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "header", [["style", "height:0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "d-flex justify-content-center"], ["style", "height:100px;padding-top:30px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "text-center"], ["style", "width:321px;height:112.5px;position:relative;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "a", [["href", "/login"], ["style", "text-decoration:none;position:absolute;top:0;left:0;width:100%;height:100%;text-indent:-999px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "img-fluid logo"], ["style", "height:58px;margin-top:14px;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["style", "margin-top:15px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["IT\u6848\u4EF6\u30FB\u4EBA\u6750\u30DE\u30C3\u30C1\u30F3\u30B0AI \u300C\u30B3\u30A2\u30E9\u300D"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-theme-switcher", [["style", "display:none;"]], null, null, null, i2.View_ThemeSwitcherComponent_0, i2.RenderType_ThemeSwitcherComponent)), i1.ɵdid(8, 114688, null, 0, i3.ThemeSwitcherComponent, [i4.AccountService], null, null)], function (_ck, _v) { _ck(_v, 8, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logoPath(); _ck(_v, 4, 0, currVal_0); }); }
export function View_BeforeLoginLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-before-login-logo", [], null, null, null, View_BeforeLoginLogoComponent_0, RenderType_BeforeLoginLogoComponent)), i1.ɵdid(1, 114688, null, 0, i5.BeforeLoginLogoComponent, [i4.AccountService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BeforeLoginLogoComponentNgFactory = i1.ɵccf("app-before-login-logo", i5.BeforeLoginLogoComponent, View_BeforeLoginLogoComponent_Host_0, {}, {}, []);
export { BeforeLoginLogoComponentNgFactory as BeforeLoginLogoComponentNgFactory };
