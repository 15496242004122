export class NewMailsCounts {
  new_projects_count: number;
  new_workers_count: number;
  new_offers_count: number;
  constructor(hash: any) {
    hash = hash || {};
    this.new_projects_count = hash.new_projects_count || 0;
    this.new_workers_count = hash.new_workers_count || 0;
    this.new_offers_count = hash.new_offers_count || 0;
  }
}
export class NewMailsIds {
  project_id: number;
  worker_id: number;
  offer_id: number;
  constructor(hash: any) {
    hash = hash || {};
    this.project_id = hash.project_id || 0;
    this.worker_id = hash.worker_id || 0;
    this.offer_id = hash.offer_id || 0;
  }
}
export class NewReceivedMails {
  counts: NewMailsCounts;
  ids: NewMailsIds;
  constructor(hash: any) {
    hash = hash || {};
    this.counts = hash.counts;
    this.ids = hash.ids;
  }
}
