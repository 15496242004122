/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calender-custom-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./calender-custom-header.component";
import * as i3 from "@angular/material/datepicker";
import * as i4 from "@angular/material/core";
var styles_CalenderCustomHeaderComponent = [i0.styles];
var RenderType_CalenderCustomHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalenderCustomHeaderComponent, data: {} });
export { RenderType_CalenderCustomHeaderComponent as RenderType_CalenderCustomHeaderComponent };
export function View_CalenderCustomHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "custom-datepicker-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "arrow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" < "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "custom-datepicker-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "arrow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" > "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.periodLabel; _ck(_v, 4, 0, currVal_0); }); }
export function View_CalenderCustomHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calender-custom-header", [], null, null, null, View_CalenderCustomHeaderComponent_0, RenderType_CalenderCustomHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i2.CalenderCustomHeaderComponent, [i3.MatCalendar, i4.DateAdapter], null, null)], null, null); }
var CalenderCustomHeaderComponentNgFactory = i1.ɵccf("app-calender-custom-header", i2.CalenderCustomHeaderComponent, View_CalenderCustomHeaderComponent_Host_0, {}, {}, []);
export { CalenderCustomHeaderComponentNgFactory as CalenderCustomHeaderComponentNgFactory };
