import { MatCalendar } from "@angular/material/datepicker";
import { DateAdapter } from "@angular/material/core";
var CalenderCustomHeaderComponent = /** @class */ (function () {
    function CalenderCustomHeaderComponent(_calendar, _dateAdapter) {
        this._calendar = _calendar;
        this._dateAdapter = _dateAdapter;
    }
    Object.defineProperty(CalenderCustomHeaderComponent.prototype, "periodLabel", {
        get: function () {
            var activeDate = this._calendar.activeDate;
            // JavaScriptの月は0から始まるので+1する
            return activeDate.getFullYear() + "/" + (activeDate.getMonth() + 1 < 10 ? '0' : '') + (activeDate.getMonth() + 1);
        },
        enumerable: true,
        configurable: true
    });
    // 前の月を表示
    CalenderCustomHeaderComponent.prototype.previousClicked = function () {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
    };
    // 次の月を表示
    CalenderCustomHeaderComponent.prototype.nextClicked = function () {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
    };
    return CalenderCustomHeaderComponent;
}());
export { CalenderCustomHeaderComponent };
