/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-request.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../component/mailer/mailer.component.ngfactory";
import * as i3 from "../component/mailer/mailer.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./confirmation-request.component";
import * as i6 from "../../service/offer.service";
import * as i7 from "ngx-toastr";
import * as i8 from "../../service/prepare-text.service";
import * as i9 from "ngx-clipboard";
import * as i10 from "../../service/google-analystics.service";
var styles_ConfirmationRequestComponent = [i0.styles];
var RenderType_ConfirmationRequestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationRequestComponent, data: {} });
export { RenderType_ConfirmationRequestComponent as RenderType_ConfirmationRequestComponent };
export function View_ConfirmationRequestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mailer", [], null, null, null, i2.View_MailerComponent_0, i2.RenderType_MailerComponent)), i1.ɵdid(1, 114688, null, 0, i3.MailerComponent, [i4.NgbActiveModal], { mailHref: [0, "mailHref"], mailTo: [1, "mailTo"], mailCc: [2, "mailCc"], mailSubject: [3, "mailSubject"], matchingOffer: [4, "matchingOffer"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mailHref; var currVal_1 = _co.mailTo; var currVal_2 = _co.mailCc; var currVal_3 = _co.mailSubject; var currVal_4 = _co.matchingOffer; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ConfirmationRequestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-request", [], null, null, null, View_ConfirmationRequestComponent_0, RenderType_ConfirmationRequestComponent)), i1.ɵdid(1, 245760, null, 0, i5.ConfirmationRequestComponent, [i4.NgbActiveModal, i6.OfferService, i7.ToastrService, i8.PrepareTextService, i9.ClipboardService, i10.GoogleAnalysticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationRequestComponentNgFactory = i1.ɵccf("app-confirmation-request", i5.ConfirmationRequestComponent, View_ConfirmationRequestComponent_Host_0, { offerId: "offerId", offerType: "offerType", projectMailId: "projectMailId", workerMailId: "workerMailId", side: "side", actionLogId: "actionLogId", matchingOffer: "matchingOffer", projectAIMailId: "projectAIMailId", workerAIMailId: "workerAIMailId" }, {}, []);
export { ConfirmationRequestComponentNgFactory as ConfirmationRequestComponentNgFactory };
