var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-toastr";
var CompanyService = /** @class */ (function (_super) {
    __extends(CompanyService, _super);
    function CompanyService(header, http, toastrService) {
        var _this = _super.call(this, header, http) || this;
        _this.toastrService = toastrService;
        return _this;
    }
    CompanyService.prototype.getCompanyList = function () {
        return this.requestGet('api/v1/company_list');
    };
    CompanyService.prototype.getCompany = function (id) {
        return this.requestGet('api/v1/company', { id: id });
    };
    CompanyService.prototype.createNewCompany = function (data) {
        return this.requestPost('api/v1/company', data);
    };
    CompanyService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient), i0.inject(i3.ToastrService)); }, token: CompanyService, providedIn: "root" });
    return CompanyService;
}(HttpClientAbstract));
export { CompanyService };
