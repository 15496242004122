var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClientAbstract } from './http-client-abstract';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
var LocationService = /** @class */ (function (_super) {
    __extends(LocationService, _super);
    function LocationService(header, http) {
        return _super.call(this, header, http) || this;
    }
    // orginからdestまでの経路のGoogleMAPで表示
    LocationService.prototype.openMap = function (workerMail, projectMail) {
        if (workerMail.work_location && projectMail.work_location) {
            // GoogleMapページ
            window.open("https://www.google.co.jp/maps/dir/?api=1&origin=" + workerMail.work_location + "&destination=" + projectMail.work_location + "&travelmode=transit", '_blank');
        }
    };
    LocationService.ngInjectableDef = i0.defineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient)); }, token: LocationService, providedIn: "root" });
    return LocationService;
}(HttpClientAbstract));
export { LocationService };
