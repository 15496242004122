import { OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FULLSTAR_EMBED_ID } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/platform-browser";
var RouterEventService = /** @class */ (function () {
    function RouterEventService(router, titleService) {
        this.router = router;
        this.titleService = titleService;
        this.subscriptions = new Subscription();
    }
    RouterEventService.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    RouterEventService.prototype.changeTabName = function () {
        var _this = this;
        var changeTabNameSub = this.router.events.subscribe(function (nav) {
            if (nav instanceof NavigationEnd) {
                if (nav.url.match(/login/)) {
                    _this.titleService.setTitle(_this.createTabName('ログイン'));
                }
                else if (nav.url.match(/matching\/projects/)) {
                    if (nav.url.match(/matching\/projects\//)) {
                        _this.titleService.setTitle(_this.createTabName('案件詳細'));
                    }
                    else {
                        _this.titleService.setTitle(_this.createTabName('案件一覧'));
                    }
                }
                else if (nav.url.match(/matching\/workers/)) {
                    if (nav.url.match(/matching\/workers\//)) {
                        _this.titleService.setTitle(_this.createTabName('人材詳細'));
                    }
                    else {
                        _this.titleService.setTitle(_this.createTabName('人材一覧'));
                    }
                }
                else if (nav.url.match(/replies/)) {
                    _this.titleService.setTitle(_this.createTabName('返信一覧'));
                }
                else if (nav.url.match(/matching\/auto/)) {
                    _this.titleService.setTitle(_this.createTabName('おすすめ'));
                }
                else if (nav.url.match(/offer/)) {
                    _this.titleService.setTitle(_this.createTabName('提案管理'));
                }
                else if (nav.url.match(/mail-register/)) {
                    _this.titleService.setTitle(_this.createTabName('案件人材管理'));
                }
                else if (nav.url.match(/report/)) {
                    _this.titleService.setTitle(_this.createTabName('レポート(β)'));
                }
                else if (nav.url.match(/account\/setting/)) {
                    _this.titleService.setTitle(_this.createTabName('設定'));
                }
                else if (nav.url.match(/registration/)) {
                    _this.titleService.setTitle(_this.createTabName('ユーザー登録'));
                }
                else if (nav.url.match(/account\/setting\/notification/)) {
                    _this.titleService.setTitle(_this.createTabName('通知設定'));
                }
                else if (nav.url.match(/account\/setting\/matching-condition/)) {
                    _this.titleService.setTitle(_this.createTabName('マッチング条件設定'));
                }
                else if (nav.url.match(/account\/setting\/business/)) {
                    _this.titleService.setTitle(_this.createTabName('BP設定'));
                }
                else if (nav.url.match(/account\/password\/change/)) {
                    _this.titleService.setTitle(_this.createTabName('パスワード変更'));
                }
                else if (nav.url.match(/account\/password\/reset\/mail/)) {
                    _this.titleService.setTitle(_this.createTabName('パスワード再発行'));
                }
                else if (nav.url.match(/privacy-policy-component/)) {
                    _this.titleService.setTitle(_this.createTabName('プライバシーポリシー'));
                }
                else if (nav.url.match(/terms-of-service/)) {
                    _this.titleService.setTitle(_this.createTabName('利用規約'));
                }
            }
        });
        this.subscriptions.add(changeTabNameSub);
    };
    RouterEventService.prototype.executeFullstarScript = function () {
        var executeFullstarScriptSub = this.router.events.subscribe(function (nav) {
            if (nav instanceof NavigationEnd) {
                var div = document.getElementById('fullstar_script');
                // fullstar_scriptの中身が重複されないための事前クリア処理
                while (div.firstChild) {
                    div.removeChild(div.firstChild);
                }
                // ページ遷移成功の場合はFullstarスクリプトを実行する（要ログイン画面のみ）
                if (!!localStorage.account && !nav.url.match(/login/)) {
                    var account = JSON.parse(localStorage.account);
                    // 五十嵐様のマスターアカウント(igarashi.keita.*@riff.co.jp)の場合は、Fullstarスクリプトを実行しない
                    if (/^igarashi\.keita\..+@riff\.co\.jp$/.test(account.email)) {
                        return;
                    }
                    // ---- Fullstar(フルスタ)埋め込みコード start ----
                    var fullstar_script = document.createElement('script');
                    var param_user_uuid = account.id; // ログインユーザーのIDなど
                    var param_user_name = account.name; //ログインユーザーの名前
                    var param_user_company = account.company_name; //ログインユーザーの会社名
                    var param_user_email = account.email; //ログインユーザーのメールアドレス
                    var param_user_plan = account.user_code; //ログインユーザーのプラン(FullStarに合わせPlanとしているが、渡すパラメータはuser_code)
                    fullstar_script.innerHTML = "\n            fs.clear();fs.show(\"" + FULLSTAR_EMBED_ID + "\", \"" + param_user_uuid + "\", {\n              company_name: \"" + param_user_company + "\",\n              user_name: \"" + param_user_name + "\",\n              user_email: \"" + param_user_email + "\",\n              plan_name: \"" + param_user_plan + "\"\n            });\n            fs.tracking();\n            fs.survey();\n            fs.guide();\n            fs.checklist();\n            fs.tooltip();\n          ";
                    div.insertAdjacentElement('afterbegin', fullstar_script);
                    // ---- Fullstar(フルスタ)埋め込みコード end ----
                }
            }
        });
        this.subscriptions.add(executeFullstarScriptSub);
    };
    RouterEventService.prototype.createTabName = function (page) {
        return page + " \uFF5C Qoala";
    };
    RouterEventService.ngInjectableDef = i0.defineInjectable({ factory: function RouterEventService_Factory() { return new RouterEventService(i0.inject(i1.Router), i0.inject(i2.Title)); }, token: RouterEventService, providedIn: "root" });
    return RouterEventService;
}());
export { RouterEventService };
