import * as i0 from "@angular/core";
var StringService = /** @class */ (function () {
    function StringService() {
    }
    // 全角英文字 -> 半角英文字
    StringService.prototype.zenkakuToHankaku = function (str) {
        return this.zenakuMinusToHankakuDash(str).replace(/[Ａ-Ｚａ-ｚ０-９．！-／：-＠［-｀｛-～、-〜”’・]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
        });
    };
    // かな -> カナに変換
    StringService.prototype.hiraToKana = function (str) {
        return str.replace(/[\u3041-\u3096]/g, function (s) {
            var chr = s.charCodeAt(0) + 0x60;
            return String.fromCharCode(chr);
        });
    };
    // 全角のマイナス -> 半角ダッシュ
    StringService.prototype.zenakuMinusToHankakuDash = function (str) {
        return str.replace('−', '-');
    };
    // 半角スペースを空白文字に変更
    StringService.prototype.replaceHalfWidthSpace = function (str) {
        return str.replace(new RegExp(' ', 'g'), '&ensp;');
    };
    //  半角英文字 -> 全角英文字
    StringService.prototype.hankakuToZenkaku = function (str) {
        return str.replace(/[A-Za-z0-9]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
        });
    };
    // 特殊文字のエスケープ
    StringService.prototype.escapeSpecialChar = function (str) {
        return str.replace(/[\\\/:*?+"<>|\^$.()\[\]{}]/g, function (ch) {
            // 特殊文字の前にバックスラッシュを付与し単に文字列として扱う
            return '\\' + ch;
        });
    };
    // メール文の文字デザインで、変更が不要なキーワードかどうかを判定
    StringService.prototype.unnecessaryKey = function (string) {
        var unnecessaryKeys = [
            'FW',
            'エンジニア',
            'OS',
            'サービス',
            'システム',
            'プロジェクト',
            'スキル',
            '開発'
        ];
        return unnecessaryKeys.includes(string);
    };
    // 末尾のハイフンを削除
    StringService.prototype.deleteEndHyphen = function (str) {
        var lastCharacter = str.slice(-1);
        // 末尾の文字がハイフン(全角・半角問わず)の場合
        if ((lastCharacter === 'ｰ') || (lastCharacter === 'ー')) {
            // 末尾の文字を削除
            str = str.slice(0, -1);
        }
        return str;
    };
    // 半角大文字に変換
    StringService.prototype.zenkakuToHankakuAndUppercase = function (str) {
        return this.zenkakuToHankaku(str).toUpperCase();
    };
    // スキル検索・ハイライトに用いるテキスト平準化関数
    StringService.prototype.textFormatForSkillSearch = function (str) {
        // 半角大文字に変換
        str = this.zenkakuToHankakuAndUppercase(str);
        // かな -> カナに変換
        str = this.hiraToKana(str);
        // スペースを削除
        str = this.replaceHalfWidthSpace(str);
        // 末尾のハイフンを削除
        str = this.deleteEndHyphen(str);
        return str;
    };
    // 配列内すべての文字列をスキル検索・ハイライト用にフォーマット
    StringService.prototype.allInArrayTextFormatForSkillSearch = function (array) {
        var _this = this;
        return array.map(function (element) {
            return _this.textFormatForSkillSearch(element);
        });
    };
    // スキル配列に対して、対象キーワードが含まれていれば、ハイライト対象としてtrueを返す
    StringService.prototype.isHighlightTerget = function (skills, keyword) {
        var uppercaseSkills = this.allInArrayTextFormatForSkillSearch(skills);
        var uppercaseKeyword = this.textFormatForSkillSearch(keyword);
        // skills、keywordどちらも半角大文字に変換して判定
        return uppercaseSkills.includes(uppercaseKeyword);
    };
    // title_json/body_json内の、条件に該当するキーワードに対し装飾classを付与する関数
    StringService.prototype.checkKeywordDecoration = function (matchedSkills, baseKeywords, vocabularyWord, meaningWord, importanceLevel) {
        // jsonの重要度が2以上の場合かつ、不要キーワードに該当しない場合は、jsonの単語名を太字化する
        if ((importanceLevel >= 2) && (this.unnecessaryKey(vocabularyWord) === false)) {
            var replactTarget = this.replaceHalfWidthSpace(vocabularyWord);
            var formattedMeaningWord = this.textFormatForSkillSearch(meaningWord);
            // baseKeywordsを半角大文字に寄せる
            var formattedBaseKeywords = this.allInArrayTextFormatForSkillSearch(baseKeywords);
            var matched = formattedBaseKeywords.includes(formattedMeaningWord) ? 'matched' : '';
            var searched = '';
            if (matchedSkills) {
                // jsonの意味語とスキル検索のキーワードが一致する場合は、spanタグにhighlightクラスを付与する
                // 例: 検索語句が「リモート」の場合、表示されている文字が「テレワーク」でも意味語が「リモート」の場合はハイライトする
                this.isHighlightTerget(matchedSkills, meaningWord) ? searched = 'highlight' : '';
            }
            vocabularyWord = "<span class=\"strong " + matched + " " + searched + "\">" + replactTarget + "</span>";
        }
        // 装飾classの付与に該当しないキーワードの場合はそのまま返す
        return vocabularyWord;
    };
    // htmlタグとして認識される < > をエスケープするための関数
    StringService.prototype.escapeHTMLTags = function (str) {
        if (str[0] === '<')
            return '&lt';
        if (str[0] === '>')
            return '&gt';
        return str[0];
    };
    StringService.ngInjectableDef = i0.defineInjectable({ factory: function StringService_Factory() { return new StringService(); }, token: StringService, providedIn: "root" });
    return StringService;
}());
export { StringService };
