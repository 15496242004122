var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from './http-client-abstract';
import { interval, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
var MailRegisterService = /** @class */ (function (_super) {
    __extends(MailRegisterService, _super);
    function MailRegisterService(header, http) {
        var _this = _super.call(this, header, http) || this;
        _this.smtp_setting_id = -1;
        _this.qmatchStatusCheckIds = new Set();
        _this.qmatchStatusCheckSubject = new Subject();
        _this.qmatchStatusCheckObservable = _this.qmatchStatusCheckSubject.asObservable();
        _this.subscriptions = new Subscription();
        _this.getSmtp().subscribe();
        // 案件人材管理のqmatch処理status更新バックグラウンドジョブ，３分ごと実行する
        var intervalSub = interval(5 * 60 * 1000).subscribe(function () {
            if (_this.qmatchStatusCheckIds.size > 0) {
                _this.getQmatchSendStatuses(Array.from(_this.qmatchStatusCheckIds.values())).subscribe(function (statuses) {
                    _this.qmatchStatusCheckSubject.next(statuses);
                });
            }
        });
        _this.subscriptions.add(intervalSub);
        return _this;
    }
    MailRegisterService.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    MailRegisterService.prototype.getMailRegisters = function (step) {
        return _super.prototype.requestGet.call(this, 'api/v1/mail_registers', { step: step });
    };
    MailRegisterService.prototype.getRegisteredMailId = function (id) {
        return _super.prototype.requestGet.call(this, 'api/v1/mail_registers/' + id + '/registered_mail_id');
    };
    MailRegisterService.prototype.getSendStatus = function (registerId, type) {
        if (type === void 0) { type = 'qmatch_send_status'; }
        return _super.prototype.requestGet.call(this, "api/v1/mail_registers/" + registerId + "/" + type)
            .pipe(map(function (res) { return res.body; }));
    };
    MailRegisterService.prototype.addQmatchSendStatusCheckId = function (id) {
        this.qmatchStatusCheckIds.add(id);
    };
    MailRegisterService.prototype.removeQmatchSendStatusCheckId = function (id) {
        this.qmatchStatusCheckIds.delete(id);
    };
    MailRegisterService.prototype.getQmatchSendStatuses = function (registerIds) {
        return _super.prototype.requestPost.call(this, 'api/v1/mail_registers/qmatch_send_statuses', {
            register_ids: registerIds
        })
            .pipe(map(function (res) { return res.body; }));
    };
    MailRegisterService.prototype.updateSendConfig = function (registerId, params) {
        params['register_id'] = registerId;
        return _super.prototype.requestPost.call(this, "api/v1/mail_registers/update_send_config", params);
    };
    MailRegisterService.prototype.updateMailRegister = function (params) {
        return _super.prototype.requestPost.call(this, 'api/v1/mail_register/update', params);
    };
    MailRegisterService.prototype.deleteMailRegisters = function (registerIds) {
        var params = { register_ids: registerIds };
        return _super.prototype.requestPost.call(this, 'api/v1/mail_registers/destroy', params);
    };
    MailRegisterService.prototype.searchFocusMail = function () {
        return _super.prototype.requestGet.call(this, 'api/v1/user_focus_mails');
    };
    MailRegisterService.prototype.createOrDeleteFocusMail = function (focus_info) {
        return _super.prototype.requestPost.call(this, 'api/v1/user_focus_mails', focus_info);
    };
    MailRegisterService.prototype.register = function (params) {
        return _super.prototype.requestPost.call(this, 'api/v1/mail_registers', params);
    };
    MailRegisterService.prototype.sendToRegister = function (mail_id, mail_type) {
        var params = { matching_mail_id: mail_id, mail_type: mail_type };
        return _super.prototype.requestPost.call(this, 'api/v1/mail_register/create_from_matching_mail', params);
    };
    MailRegisterService.prototype.goToRegisteredMailPage = function (mailRegister) {
        if (mailRegister.mail_type === 'project') {
            window.open('/matching/projects/' + mailRegister.registered_mail_id, '_blank');
        }
        else {
            window.open('/matching/workers/' + mailRegister.registered_mail_id, '_blank');
        }
    };
    MailRegisterService.prototype.parseAddressCSV = function (files) {
        var params = { files: files };
        return _super.prototype.requestPostForm.call(this, 'api/v1/mail_registers/parse_address_csv', files)
            .pipe(map(function (res) { return res.body; }));
    };
    MailRegisterService.prototype.textSearch = function (text, step) {
        var params = { text: text, step: step };
        return _super.prototype.requestPost.call(this, 'api/v1/mail_registers/text_search', params)
            .pipe(map(function (res) { return res.body; }));
    };
    // smtp_settings
    MailRegisterService.prototype.setSmtp = function (params) {
        var _this = this;
        return this.requestPost('api/v1/smtp_settings', params).pipe(map(function (res) {
            _this.smtp_setting_id = res.body.id;
            _this.smtp_setting = res.body;
        }));
    };
    MailRegisterService.prototype.getSmtp = function () {
        var _this = this;
        return this.requestGet('api/v1/smtp_settings').pipe(map(function (res) {
            _this.smtp_setting_id = res.body.id || -1;
            _this.smtp_setting = res.body;
        }));
    };
    MailRegisterService.prototype.testSmtp = function (test_email) {
        var id = this.smtp_setting_id;
        return this.requestPost('api/v1/smtp_settings/test', {
            id: id,
            test_email: test_email
        });
    };
    MailRegisterService.prototype.smtpTestStatus = function () {
        var id = this.smtp_setting_id;
        return this.requestPost('api/v1/smtp_settings/test_status', { id: id }).pipe(map(function (res) { return res.body.test_status; }));
    };
    // メールCSVアップロード
    MailRegisterService.prototype.uploadMailCSV = function (file) {
        return this.requestPostForm('api/v1/mail_register/create_from_csv', file);
    };
    MailRegisterService.prototype.checkOpenToCloud = function () {
        return this.requestGet('api/v1/mail_registers/check_open_to_cloud');
    };
    MailRegisterService.ngInjectableDef = i0.defineInjectable({ factory: function MailRegisterService_Factory() { return new MailRegisterService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient)); }, token: MailRegisterService, providedIn: "root" });
    return MailRegisterService;
}(HttpClientAbstract));
export { MailRegisterService };
