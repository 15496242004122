<header style="height:0;">
  <div class="d-flex justify-content-center" style="height:100px;padding-top:30px;">
    <div class="text-center" style="width:321px;height:112.5px;position:relative;">
      <a href="/login"
        style="text-decoration:none;position:absolute;top:0;left:0;width:100%;height:100%;text-indent:-999px;"></a>
      <img class="img-fluid logo" [src]="logoPath()" style="height:58px;margin-top:14px;" />
      <p style="margin-top:15px;">IT案件・人材マッチングAI 「コアラ」</p>
      <app-theme-switcher style="display:none;"></app-theme-switcher>
    </div>
  </div>
</header>