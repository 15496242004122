import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: [
    './terms-of-service.component.scss',
    '../../shared/style/service-rule.scss'
  ]
})
export class TermsOfServiceComponent implements OnInit {
  sessionStorage: object;

  constructor() {}

  ngOnInit() {
    this.sessionStorage = sessionStorage;
  }

  isLogin() {
    return !!localStorage.account;
  }
}
