import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mailQuotes'
})
export class MailQuotesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    // 先頭の文字と改行文字(\n)に > を連結
    value = value.replace(/^/gi, '> ');
    value = value.replace(/\n/gi, '\n> ');
    return value;
  }
}
