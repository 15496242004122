export class DetailPageInfo {
  public keywordPage: number = 0;
  public infiniteScrollEnd = false;

  constructor(hash: any = {}) {
    if (hash) {
      this.keywordPage = hash.keywordPage || 0;
      this.infiniteScrollEnd =
        hash.infiniteScrollEnd === undefined
          ? this.infiniteScrollEnd
          : hash.infiniteScrollEnd;
    }
  }

  // API送信用ハッシュを作成（変数名をスネークケース化）
  getParams() {
    return {
      keyword_page: this.keywordPage
    };
  }
}
