import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// model
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { SearchService } from 'src/app/shared/service/search/search.service';
import { API_SERVER_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchProjectService extends SearchService {
  // サービス毎に初期値を設定する
  initPage = { page: 1 };
  initPerPage = { perPage: 50 }; // TODO: 未使用（API側で200件ずつ取得するようになっているので使用できるようにすべき）
  selfServiceName = 'SearchProjectService';

  constructor(header: HttpHeaderService, http: HttpClient) {
    super(header, http);
  }

  doSearch(id?: string, sortParams?: object): Observable<any> {
    return super.requestPost(
      'api/v1/projects',
      this.getSearchParameters(sortParams)
    );
  }

  searchFocusMail(): Observable<any> {
    return super.requestGet(
      'api/v1/user_focus_mails?mail_side=project',
      this.getSearchParameters()
    );
  }

  searchReadMail(): Observable<any> {
    return super.requestGet(
      'api/v1/read_mails?mail_side=project',
      this.getSearchParameters()
    );
  }

  createOrDeleteFocusMail(focus_info): Observable<any> {
    return super.requestPost('api/v1/user_focus_mails', focus_info);
  }

  getSearchKeyword() {
    return super.requestGet(
      'api/v1/projects/index_search_keyword',
      this.getSearchParameters()
    );
  }

  getBestMails(): Observable<any> {
    return super.requestGet('api/v1/projects/best_mails');
  }
}
