var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
var ErrorFeedbackService = /** @class */ (function (_super) {
    __extends(ErrorFeedbackService, _super);
    function ErrorFeedbackService(header, http) {
        return _super.call(this, header, http) || this;
    }
    ErrorFeedbackService.prototype.getActionLogIdForCorrectingMailInfo = function (params) {
        return _super.prototype.requestPost.call(this, 'api/v1/correcting_mail_info/get/action_log_id', params);
    };
    ErrorFeedbackService.prototype.sendCorrectedMailInfo = function (params) {
        return _super.prototype.requestPost.call(this, 'api/v1/correcting_mail_info/correct/mail_info', params);
    };
    ErrorFeedbackService.prototype.getErrorFeedbackInfo = function (params) {
        return _super.prototype.requestGet.call(this, 'api/v1/error_feedback/get_error_feedback_info', params);
    };
    ErrorFeedbackService.prototype.saveErrorFeedbackInfo = function (params) {
        return _super.prototype.requestPost.call(this, 'api/v1/error_feedback/save_error_feedback_info', params);
    };
    ErrorFeedbackService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorFeedbackService_Factory() { return new ErrorFeedbackService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient)); }, token: ErrorFeedbackService, providedIn: "root" });
    return ErrorFeedbackService;
}(HttpClientAbstract));
export { ErrorFeedbackService };
