var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
// model
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { SearchService } from 'src/app/shared/service/search/search.service';
import * as i0 from "@angular/core";
import * as i1 from "../http-header.service";
import * as i2 from "@angular/common/http";
var SearchProjectService = /** @class */ (function (_super) {
    __extends(SearchProjectService, _super);
    function SearchProjectService(header, http) {
        var _this = _super.call(this, header, http) || this;
        // サービス毎に初期値を設定する
        _this.initPage = { page: 1 };
        _this.initPerPage = { perPage: 50 }; // TODO: 未使用（API側で200件ずつ取得するようになっているので使用できるようにすべき）
        _this.selfServiceName = 'SearchProjectService';
        return _this;
    }
    SearchProjectService.prototype.doSearch = function (id, sortParams) {
        return _super.prototype.requestPost.call(this, 'api/v1/projects', this.getSearchParameters(sortParams));
    };
    SearchProjectService.prototype.searchFocusMail = function () {
        return _super.prototype.requestGet.call(this, 'api/v1/user_focus_mails?mail_side=project', this.getSearchParameters());
    };
    SearchProjectService.prototype.searchReadMail = function () {
        return _super.prototype.requestGet.call(this, 'api/v1/read_mails?mail_side=project', this.getSearchParameters());
    };
    SearchProjectService.prototype.createOrDeleteFocusMail = function (focus_info) {
        return _super.prototype.requestPost.call(this, 'api/v1/user_focus_mails', focus_info);
    };
    SearchProjectService.prototype.getSearchKeyword = function () {
        return _super.prototype.requestGet.call(this, 'api/v1/projects/index_search_keyword', this.getSearchParameters());
    };
    SearchProjectService.prototype.getBestMails = function () {
        return _super.prototype.requestGet.call(this, 'api/v1/projects/best_mails');
    };
    SearchProjectService.ngInjectableDef = i0.defineInjectable({ factory: function SearchProjectService_Factory() { return new SearchProjectService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient)); }, token: SearchProjectService, providedIn: "root" });
    return SearchProjectService;
}(SearchService));
export { SearchProjectService };
