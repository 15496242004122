/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-feedback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "./error-feedback.component";
import * as i6 from "../../service/error-feedback.service";
import * as i7 from "ngx-toastr";
import * as i8 from "@angular/router";
var styles_ErrorFeedbackComponent = [i0.styles];
var RenderType_ErrorFeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorFeedbackComponent, data: {} });
export { RenderType_ErrorFeedbackComponent as RenderType_ErrorFeedbackComponent };
function View_ErrorFeedbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "anotation error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30A8\u30E9\u30FC\u5831\u544A\u3059\u308B\u9805\u76EE\u306E\u9078\u629E\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002 "]))], null, null); }
function View_ErrorFeedbackComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0"])), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "i", [["class", "fa fa-question-circle"], ["ngbTooltip", "\u4EE5\u5F8C\u3001\u5019\u88DC\u3068\u3057\u3066\u8868\u793A\u3055\u308C\u306A\u304F\u306A\u308A\u307E\u3059\u3002\u4E00\u89A7\u753B\u9762\u3084\u4ED6\u306E\u30E1\u30FC\u30EB\u306E\u5019\u88DC\u3068\u3057\u3066\u306F\u5F15\u304D\u7D9A\u304D\u8868\u793A\u3055\u308C\u307E\u3059\u3002"]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = "\u4EE5\u5F8C\u3001\u5019\u88DC\u3068\u3057\u3066\u8868\u793A\u3055\u308C\u306A\u304F\u306A\u308A\u307E\u3059\u3002\u4E00\u89A7\u753B\u9762\u3084\u4ED6\u306E\u30E1\u30FC\u30EB\u306E\u5019\u88DC\u3068\u3057\u3066\u306F\u5F15\u304D\u7D9A\u304D\u8868\u793A\u3055\u308C\u307E\u3059\u3002"; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ErrorFeedbackComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0"])), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "i", [["class", "fa fa-question-circle"], ["ngbTooltip", "\u4EE5\u5F8C\u3001\u5019\u88DC\u3068\u3057\u3066\u3060\u3051\u3067\u306A\u304F\u3001\u4E00\u89A7\u753B\u9762\u3067\u3082\u4E00\u5207\u8868\u793A\u3055\u308C\u306A\u304F\u306A\u308A\u307E\u3059\u3002"]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = "\u4EE5\u5F8C\u3001\u5019\u88DC\u3068\u3057\u3066\u3060\u3051\u3067\u306A\u304F\u3001\u4E00\u89A7\u753B\u9762\u3067\u3082\u4E00\u5207\u8868\u793A\u3055\u308C\u306A\u304F\u306A\u308A\u307E\u3059\u3002"; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ErrorFeedbackComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "label", [["for", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "custom-control custom-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["class", "custom-control-input"]], [[8, "id", 0], [8, "type", 0], [8, "name", 0], [8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = ((_v.parent.parent.context.$implicit.error_feedback.format_choice_answer = !_v.parent.parent.context.$implicit.error_feedback.format_choice_answer) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [["class", "custom-control-label"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorFeedbackComponent_5)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorFeedbackComponent_6)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_6 = (_v.parent.parent.context.$implicit.error_feedback_item.table_to_delete_record_from === "matching_score"); _ck(_v, 7, 0, currVal_6); var currVal_7 = ((_v.parent.parent.context.$implicit.error_feedback_item.table_to_delete_record_from === "mail") && !_v.parent.parent.context.$implicit.error_feedback_item.parent_item_id); _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "customCheck", _v.parent.parent.context.index, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.error_feedback_item.format_type, ""); var currVal_2 = i1.ɵinlineInterpolate(2, "", _v.parent.parent.context.$implicit.error_feedback_item.format_type, "", _v.parent.parent.context.$implicit.error_feedback_item.id, ""); var currVal_3 = _v.parent.parent.context.$implicit.error_feedback.format_choice_answer; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "customCheck", _v.parent.parent.context.index, ""); _ck(_v, 4, 0, currVal_4); var currVal_5 = _v.parent.parent.context.$implicit.error_feedback_item.text; _ck(_v, 5, 0, currVal_5); }); }
function View_ErrorFeedbackComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorFeedbackComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.parent.context.$implicit.error_feedback_item.table_to_delete_record_from === "mail") || _co.matchingScoreId); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ErrorFeedbackComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "text-item-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Qoala\u904B\u55B6\u30C1\u30FC\u30E0\u3078\u306E\u5831\u544A"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "textarea", [["class", "form-control"], ["placeholder", "\u304A\u5BC4\u305B\u3044\u305F\u3060\u3044\u305F\u60C5\u5831\u306F\u30B5\u30FC\u30D3\u30B9\u6539\u5584\u306B\u6D3B\u7528\u3055\u305B\u3066\u3044\u305F\u3060\u304D\u307E\u3059\u3002\n\u3054\u5354\u529B\u306E\u307B\u3069\u3001\u3069\u3046\u305E\u3088\u308D\u3057\u304F\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002"], ["rows", "5"], ["style", "height: 85px;"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_v.parent.context.$implicit.error_feedback.format_text_answer = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(9, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["          "]))], function (_ck, _v) { var currVal_7 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.error_feedback_item.format_type, ""); var currVal_8 = _v.parent.context.$implicit.error_feedback.format_text_answer; _ck(_v, 7, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ErrorFeedbackComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "item-box"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "child-item-box": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorFeedbackComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorFeedbackComponent_7)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "item-box"; var currVal_1 = _ck(_v, 3, 0, !!_v.context.$implicit.error_feedback_item.parent_item_id); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit.error_feedback_item.format_type === "checkbox"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_v.context.$implicit.error_feedback_item.format_type === "text"); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_ErrorFeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"], ["class", "close-button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorFeedbackComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorFeedbackComponent_2)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-light"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AD\u30E3\u30F3\u30BB\u30EB "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendErrorFeedback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u524A\u9664 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.errorFeedbackInfos; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_ErrorFeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-feedback", [], null, null, null, View_ErrorFeedbackComponent_0, RenderType_ErrorFeedbackComponent)), i1.ɵdid(1, 245760, null, 0, i5.ErrorFeedbackComponent, [i2.NgbActiveModal, i6.ErrorFeedbackService, i7.ToastrService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorFeedbackComponentNgFactory = i1.ɵccf("app-error-feedback", i5.ErrorFeedbackComponent, View_ErrorFeedbackComponent_Host_0, { mailInfo: "mailInfo", mailType: "mailType", page: "page", matchingScoreId: "matchingScoreId" }, {}, []);
export { ErrorFeedbackComponentNgFactory as ErrorFeedbackComponentNgFactory };
