export class SearchPage {
  public page = 1;

  constructor(hash: any = {}) {
    if (Object.keys(hash).length) {
      this.page = hash.page;
    }
  }

  // API送信用ハッシュを作成（変数名をスネークケース化）
  getParams() {
    return {
      page: this.page
    };
  }
}
