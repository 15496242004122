<div class="modal-header">
  <h4 class="modal-title">送信方法の選択</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(matchingOffer)">
    <span class="close-button" aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span>以下のボタンをクリックしてメールソフトを立ち上げてください</span>
</div>
<div class="modal-footer">
  <button class="btn btn-primary-dark mail-button" *ngIf="mailHref" (click)="openMailer()">
    <i class="fa fa-envelope-o"></i>メールソフトを起動
  </button>
</div>
