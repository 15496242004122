var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClientAbstract } from './http-client-abstract';
import { AccountService } from './account.service';
import { ToastrService } from 'ngx-toastr';
import { GoogleAnalysticsService } from 'src/app/shared/service/google-analystics.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./account.service";
import * as i4 from "ngx-toastr";
import * as i5 from "./google-analystics.service";
var OfferService = /** @class */ (function (_super) {
    __extends(OfferService, _super);
    function OfferService(header, http, accountService, toastrService, googleAnalysticsService) {
        var _this = _super.call(this, header, http) || this;
        _this.accountService = accountService;
        _this.toastrService = toastrService;
        _this.googleAnalysticsService = googleAnalysticsService;
        _this.sharedOffersSubject = new Subject();
        _this.manualOfferRefreshPage = new Subject();
        _this.subscriptions = new Subscription();
        return _this;
    }
    OfferService.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    OfferService.prototype.getOfferInfo = function (offerId, offerType) {
        var params = { offer_type: offerType };
        return _super.prototype.requestGet.call(this, 'api/v1/offer/' + offerId, params);
    };
    OfferService.prototype.getSharedOffers = function () {
        this.refreshSharedOffersList();
        return this.sharedOffersSubject.asObservable();
    };
    OfferService.prototype.refreshSharedOffersList = function () {
        var _this = this;
        var refreshSub = _super.prototype.requestGet.call(this, 'api/v1/offer/shared_offers').subscribe(function (res) {
            _this.sharedOffersSubject.next(res);
        });
        this.subscriptions.add(refreshSub);
    };
    OfferService.prototype.getSummary = function () {
        return _super.prototype.requestGetfile.call(this, 'api/v1/offer_summary');
    };
    // 人材確認依頼
    OfferService.prototype.confirmWorker = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/confirm_worker', offer);
    };
    // 案件確認依頼
    OfferService.prototype.confirmProject = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/confirm_project', offer);
    };
    // 検討中
    OfferService.prototype.consider = function (offerPair, projectAIMailId, workerAIMailId) {
        var _this = this;
        _super.prototype.requestPost.call(this, 'api/v1/offer/consider', offerPair).subscribe(function (res) {
            _this.toastrService.success('提案管理に送りました');
            _this.refreshSharedOffersList();
            _this.googleAnalysticsService.eventEmit('提案管理ボタン', projectAIMailId, workerAIMailId, 0);
        }, function (err) {
            _this.toastrService.error('提案管理に送信に失敗しました');
        });
    };
    OfferService.prototype.consider_ai_id = function (offerForm) {
        var _this = this;
        var offerPair = offerForm.value;
        _super.prototype.requestPost.call(this, 'api/v1/offer/consider', offerPair).subscribe({
            next: function (res) {
                _this.toastrService.success('保存しました');
                _this.googleAnalysticsService.eventEmit('手動入力提案管理ボタン', offerPair['worker_ai_mail_id'], offerPair['project_ai_mail_id'], 0);
                _this.manualOfferRefreshPage.next();
                offerForm.reset();
            },
            error: function (err) {
                switch (err.status) {
                    case 404:
                        _this.toastrService.error('IDのご確認をお願いします', '保存に失敗しました');
                        break;
                    case 409:
                        _this.toastrService.warning('提案は既に存在しています');
                        break;
                    default:
                        _this.toastrService.error('保存に失敗しました');
                }
            }
        });
    };
    // 案件募集終了
    OfferService.prototype.cancelProject = function (offerPair) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/cancel_project', offerPair);
    };
    // 人材募集終了
    OfferService.prototype.cancelWorker = function (offerPair) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/cancel_worker', offerPair);
    };
    // #####################################
    // 以下オファー画面より
    // #####################################
    OfferService.prototype.resetOffer = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/reset_offer', offer);
    };
    OfferService.prototype.deleteOffer = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/delete_offer', offer);
    };
    OfferService.prototype.cancelOffer = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/cancel_offer', offer);
    };
    OfferService.prototype.rejectOfferWorker = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/reject_offer_worker', offer);
    };
    OfferService.prototype.rejectOfferProject = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/reject_offer_project', offer);
    };
    OfferService.prototype.failedOffer = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/failed_offer', offer);
    };
    OfferService.prototype.acceptedOfferWorker = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/accepted_offer_worker', offer);
    };
    OfferService.prototype.acceptedOfferProject = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/accepted_offer_project', offer);
    };
    OfferService.prototype.succeededOffer = function (offer) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/succeeded_offer', offer);
    };
    OfferService.prototype.checkWorkerOrProject = function (paramsAddCheckSide) {
        return _super.prototype.requestPost.call(this, 'api/v1/offer/send_checking_mail', paramsAddCheckSide);
    };
    // 承諾・辞退入力画面
    OfferService.prototype.approveConfirmationResponse = function (params) {
        return _super.prototype.requestGet.call(this, "api/v1/offer/approve_confirmation_response?token=" + params.token);
    };
    // #####################################
    // 以下オファーに対する承諾回答
    // #####################################
    OfferService.prototype.answerForApproveQuestionRequest = function (params) {
        return _super.prototype.requestPost.call(this, "api/v1/approve_func_question_answer", params);
    };
    // ############################
    // 提案管理のステータス表示ロジック
    // ############################
    OfferService.prototype.isProjectSide = function (mailType) {
        return mailType === 'project';
    };
    // 提案管理に送信されているか
    OfferService.prototype.isOffered = function (matchingOffer) {
        return matchingOffer.progress_status !== null;
    };
    // 検討中
    OfferService.prototype.isConsidering = function (matchingOffer) {
        return matchingOffer.progress_status === 'considering';
    };
    // BP連絡待ち
    OfferService.prototype.isWaitingQuery = function (matchingOffer, mailType) {
        if (this.isProjectSide(mailType)) {
            // tslint:disable-next-line:max-line-length
            return (!matchingOffer.send_mail_project &&
                !matchingOffer.approved_project &&
                !matchingOffer.rejected_project &&
                this.isOffered(matchingOffer));
        }
        else {
            // tslint:disable-next-line:max-line-length
            return (!matchingOffer.send_mail_worker &&
                !matchingOffer.approved_worker &&
                !matchingOffer.rejected_worker &&
                this.isOffered(matchingOffer));
        }
    };
    // BP回答待ち
    OfferService.prototype.isChecking = function (matchingOffer, mailType) {
        if (this.isProjectSide(mailType)) {
            return (matchingOffer.send_mail_project &&
                !matchingOffer.approved_project &&
                !matchingOffer.rejected_project);
        }
        else {
            return (matchingOffer.send_mail_worker &&
                !matchingOffer.approved_worker &&
                !matchingOffer.rejected_worker);
        }
    };
    // 承諾
    OfferService.prototype.isApproved = function (matchingOffer, mailType) {
        if (!this.isInterviewing(matchingOffer) &&
            !this.isSucceeded(matchingOffer)) {
            if (this.isProjectSide(mailType)) {
                return matchingOffer.approved_project;
            }
            else {
                return matchingOffer.approved_worker;
            }
        }
    };
    // 辞退
    OfferService.prototype.isRejected = function (matchingOffer, mailType) {
        if (this.isProjectSide(mailType)) {
            return matchingOffer.rejected_project;
        }
        else {
            return matchingOffer.rejected_worker;
        }
    };
    // 面談中
    OfferService.prototype.isInterviewing = function (matchingOffer) {
        return matchingOffer.progress_status === 'interviewing';
    };
    // 成立
    OfferService.prototype.isSucceeded = function (matchingOffer) {
        return matchingOffer.progress_status === 'succeeded';
    };
    // 不成立
    OfferService.prototype.isFailed = function (matchingOffer) {
        return matchingOffer.progress_status === 'failed';
    };
    // キャンセル
    OfferService.prototype.isCancelled = function (matchingOffer) {
        return matchingOffer.progress_status === 'cancelled';
    };
    // ステータス変更不能
    OfferService.prototype.isUnchangeable = function (matchingOffer) {
        return (this.isSucceeded(matchingOffer) ||
            this.isFailed(matchingOffer) ||
            this.isCancelled(matchingOffer));
    };
    // 複数提案中
    OfferService.prototype.isDubbleOffered = function (matchingOffer, mailType) {
        if (this.isProjectSide(mailType)) {
            return matchingOffer.double_offered_project;
        }
        else {
            return matchingOffer.double_offered_worker;
        }
    };
    OfferService.ngInjectableDef = i0.defineInjectable({ factory: function OfferService_Factory() { return new OfferService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient), i0.inject(i3.AccountService), i0.inject(i4.ToastrService), i0.inject(i5.GoogleAnalysticsService)); }, token: OfferService, providedIn: "root" });
    return OfferService;
}(HttpClientAbstract));
export { OfferService };
