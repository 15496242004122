/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-download.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./file-download.component";
import * as i3 from "../../shared/service/file-download.service";
import * as i4 from "ngx-toastr";
var styles_FileDownloadComponent = [i0.styles];
var RenderType_FileDownloadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileDownloadComponent, data: {} });
export { RenderType_FileDownloadComponent as RenderType_FileDownloadComponent };
export function View_FileDownloadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30D5\u30A1\u30A4\u30EB\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3057\u3066\u3044\u307E\u3059\u3002"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u3057\u3070\u3089\u304F\u304A\u5F85\u3061\u304F\u3060\u3055\u3044\u3002"]))], null, null); }
export function View_FileDownloadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-download", [], null, null, null, View_FileDownloadComponent_0, RenderType_FileDownloadComponent)), i1.ɵdid(1, 180224, null, 0, i2.FileDownloadComponent, [i3.FileDownloadService, i4.ToastrService], null, null)], null, null); }
var FileDownloadComponentNgFactory = i1.ɵccf("app-file-download", i2.FileDownloadComponent, View_FileDownloadComponent_Host_0, {}, {}, []);
export { FileDownloadComponentNgFactory as FileDownloadComponentNgFactory };
