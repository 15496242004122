import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var ClickEventService = /** @class */ (function () {
    function ClickEventService() {
        this.projectTabSubject = new Subject();
        this.projectTabState = this.projectTabSubject.asObservable();
        this.workerTabSubject = new Subject();
        this.workerTabState = this.workerTabSubject.asObservable();
        this.autoTabSubject = new Subject();
        this.autoTabState = this.autoTabSubject.asObservable();
        this.offerTabSubject = new Subject();
        this.offerTabState = this.offerTabSubject.asObservable();
    }
    ClickEventService.ngInjectableDef = i0.defineInjectable({ factory: function ClickEventService_Factory() { return new ClickEventService(); }, token: ClickEventService, providedIn: "root" });
    return ClickEventService;
}());
export { ClickEventService };
