var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
var ReportService = /** @class */ (function (_super) {
    __extends(ReportService, _super);
    function ReportService(header, http) {
        return _super.call(this, header, http) || this;
    }
    ReportService.prototype.getReport = function () {
        return this.requestGet('api/v1/reports');
    };
    ReportService.ngInjectableDef = i0.defineInjectable({ factory: function ReportService_Factory() { return new ReportService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient)); }, token: ReportService, providedIn: "root" });
    return ReportService;
}(HttpClientAbstract));
export { ReportService };
