import { Component, OnInit } from '@angular/core';
import { RouterEventService } from './shared/service/router-event.service';

import { AccountService } from 'src/app/shared/service/account.service';
import { Account } from 'src/app/shared/model/account.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  sessionStorage: object;
  title = 'app';
  account: Account;

  constructor(
    private routerEventService: RouterEventService,
    public accountService: AccountService
  ) {}

  ngOnInit() {
    this.routerEventService.changeTabName();
    this.sessionStorage = sessionStorage;
    this.account = this.accountService.getAccount();
    this.routerEventService.executeFullstarScript();
  }

  isLogin() {
    return !!localStorage.account;
  }
}
