<div class="container">
  <app-admin-message-publisher></app-admin-message-publisher>
  <form [formGroup]="newUserForm" (ngSubmit)="onSubmit()">
    <div class="form_card">
      <label style="display: inline-block">
        Company:
        <select formControlName="company_id">
          <option type="number" *ngFor="let company of companies" value="{{ company.id }}">{{ company.company_code }}</option>
        </select>
      </label>
      <label style="display: inline-block;">
        Company_STG:
        <select formControlName="company_stg_id" disabled>
          <option type="number" *ngFor="let company of companies_stg" value="{{ company.id }}">{{ company.company_code }}</option>
        </select>
      </label>
      <button type="button" (click)="getCompanyList()">
        reload List
        <i class="fa fa-refresh fa-lg" aria-hidden="true"></i>
      </button>

      <div *ngIf="company_id.value === '-1' || company_stg_id.value === '-1'" formGroupName="company_detail">
        <label>
          Company Name:
          <input type="text" formControlName="name" autocomplete="off" />
        </label>

        <label>
          Company Code:
          <input type="text" formControlName="company_code" autocomplete="off" />
        </label>

        <label>
          Mail Domain:
          <input type="text" formControlName="mail_domain" autocomplete="off" />
        </label>

        <label>
          Mail Account:
          <input type="email" formControlName="representative_mail_account" autocomplete="off" />
        </label>
      </div>

      <button type="button" (click)="addNewUser()">Add new user</button>

      <label style="display: inline-block">
        <p>
          Sync to STG:
          <i *ngIf="needSync" class="fa fa-check-square fa-lg" aria-hidden="true"></i>
          <i *ngIf="!needSync" class="fa fa-square fa-lg" aria-hidden="true"></i>
        </p>
        <input type="checkbox" [(ngModel)]="needSync" [ngModelOptions]="{ standalone: true }" style="display: none;" />
      </label>

      <button type="submit" [disabled]="!newUserForm.valid">Submit Form</button>
    </div>

    <div formArrayName="users" class="user_details">
      <div *ngFor="let user of users.controls; let i = index" class="form_card">
        <div [formGroupName]="i">
          <p>No.{{ i + 1 }}</p>
          <label>
            Name:
            <input type="text" formControlName="name" autocomplete="off" />
          </label>

          <label>
            Email:
            <input type="email" formControlName="email" autocomplete="off" />
          </label>

          <label>
            User_code:
            <input type="text" formControlName="user_code" autocomplete="off" />
          </label>

          <label>
            Auth_level:
            <input type="number" formControlName="auth" autocomplete="off" readonly />
          </label>

          <label>
            Passwd:
            <input type="password" formControlName="password" autocomplete="new-password" />
          </label>

          <label>
            Passwd_confirm:
            <input type="password" formControlName="password_confirm" autocomplete="new-password" />
          </label>

          <label>
            Gross_maigin_setting:
            <input type="number" formControlName="gross_margin_setting" autocomplete="off" />
          </label>

          <button type="button" (click)="deleteUser(i)">
            Delete this user
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="container" style="margin-top: 0;">
  <div class="form_card form_card_large">
    Freeze account switcher

    <label style="display: inline-block">
      Company List:
      <select [(ngModel)]="company_selected">
        <option type="number" value = "" selected></option>
        <option type="number" *ngFor = "let company of companies" value="{{ company.company_code }}">{{ company.company_code }}</option>
      </select>
    </label>
    <form style="display: block;" (ngSubmit)="freeze()">
      <label style="display: inline-block">
        User List:
        <select  [formControl]="freezeUser">
          <option type="number" value=-1 selected></option>
          <ng-container *ngFor="let user of userList">
            <option type="number" *ngIf="user.company_code === company_selected" value="{{ user.id }}">{{ user.email }}</option>
          </ng-container>
        </select>
      </label>

      <button type="button" (click)="loadUsers()">
        reload List
        <i class="fa fa-refresh fa-lg" aria-hidden="true"></i>
      </button>

      <button type="submit">change</button>
    </form>
  </div>

</div>