import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './shared/service/auth-guard.service';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponentComponent } from './pages/privacy-policy-component/privacy-policy-component.component';
import { ReportComponent } from './pages/report/report.component';
import { FileDownloadComponent } from './pages/file-download/file-download.component';
import { RegistrationComponent } from './pages/registration/registration.component';

const routes: Routes = [
  // ログインしているときのルーティング
  {
    path: 'matching',
    loadChildren: './pages/matching/matching.module#MatchingModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'replies',
    loadChildren: './pages/reply/reply.module#ReplyModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'offer',
    loadChildren: './pages/offer/offer.module#OfferModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    loadChildren: './pages/account/account.module#AccountModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'mail-register',
    loadChildren:
      './pages/mail-register/mail-register.module#MailRegisterModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'file-download',
    component: FileDownloadComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [AuthGuard]
  },
  // 未ログイン時のルーティング
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent
  },
  {
    path: 'privacy-policy-component',
    component: PrivacyPolicyComponentComponent
  },
  {
    path: 'approve-confirmation-response',
    loadChildren:
      './pages/approve-confirmation-response/approve-confirmation-response.module#ApproveConfirmationResponseModule'
  },
  {
    path: 'approve-confirmation-thankyou',
    loadChildren:
      './pages/approve-confirmation-thankyou/approve-confirmation-thankyou.module#ApproveConfirmationThankyouModule'
  },
  {
    path: 'login',
    loadChildren: './pages/session/session.module#SessionModule'
  },
  // ルートルーティングとワイルドカード
  {
    path: '',
    redirectTo: '/matching/projects',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/matching/projects',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
