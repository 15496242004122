import { AccountService } from 'src/app/shared/service/account.service';
import { APP_KEY } from 'src/environments/environment';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "./account.service";
var HttpHeaderService = /** @class */ (function () {
    function HttpHeaderService(accountService) {
        this.accountService = accountService;
    }
    HttpHeaderService.prototype.getHeader = function (account, contentType) {
        if (contentType === void 0) { contentType = 'json'; }
        var params = {
            'X-App-Key': APP_KEY,
            Authorization: account.accessToken,
            client: account.client,
            uid: account.uid,
            'access-token': account.accessToken // Devise対応
        };
        if (contentType === 'json') {
            Object.assign(params, { 'Content-Type': 'application/json' });
        }
        return new HttpHeaders(params);
    };
    HttpHeaderService.prototype.getHttpOptions = function (params) {
        var account = this.accountService.getAccount();
        // HTTPヘッダ情報
        var header = this.getHeader(account);
        // HTTPボディパラメータ
        var paramsOptions = { fromObject: params, encoder: new CustomEncoder() };
        return {
            headers: header,
            params: new HttpParams(paramsOptions),
            observe: 'response'
        };
    };
    HttpHeaderService.prototype.getHttpOptionsFormData = function (params) {
        var account = this.accountService.getAccount();
        // HTTPヘッダ情報
        var header = this.getHeader(account, 'dataForm');
        // HTTPボディパラメータ
        var paramsOptions = { fromObject: params, encoder: new CustomEncoder() };
        return {
            headers: header,
            params: new HttpParams(paramsOptions),
            observe: 'response'
        };
    };
    HttpHeaderService.prototype.getSTGHttpOptions = function (params) {
        var account = this.accountService.getSTGAccount();
        // HTTPヘッダ情報
        var header = this.getHeader(account);
        // HTTPボディパラメータ
        var paramsOptions = { fromObject: params, encoder: new CustomEncoder() };
        return {
            headers: header,
            params: new HttpParams(paramsOptions),
            observe: 'response'
        };
    };
    HttpHeaderService.ngInjectableDef = i0.defineInjectable({ factory: function HttpHeaderService_Factory() { return new HttpHeaderService(i0.inject(i1.AccountService)); }, token: HttpHeaderService, providedIn: "root" });
    return HttpHeaderService;
}());
export { HttpHeaderService };
var CustomEncoder = /** @class */ (function () {
    function CustomEncoder() {
    }
    CustomEncoder.prototype.encodeKey = function (key) {
        return encodeURIComponent(key);
    };
    CustomEncoder.prototype.encodeValue = function (value) {
        return encodeURIComponent(value);
    };
    CustomEncoder.prototype.decodeKey = function (key) {
        return decodeURIComponent(key);
    };
    CustomEncoder.prototype.decodeValue = function (value) {
        return decodeURIComponent(value);
    };
    return CustomEncoder;
}());
