<div class="modal-header">
  <h4 class="modal-title">タグを編集</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span class="close-button" aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form  (ngSubmit)="createNewTag()">
    <label>
      新しいタグを作成:
      <input type="text" [formControl]="newTagName" required>
    </label>
    <button class="btn btn-primary-dark border-0" type="submit" [disabled]="!newTagName.valid">作成</button>
  </form>

  <ng-container *ngFor="let tag of allTags">
    <app-tag-button [tagName]="tag.tag_name" [activated]="taggedIds.includes(tag.id)" [buttonMode]="true"
      (tagClick)="selectTag($event,tag.id)"
      (tagDelete)="openConfirmDeleteModal(tag, confirmDeleteModal)"></app-tag-button>
  </ng-container>
</div>
<div class="modal-footer"></div>

<ng-template #confirmDeleteModal let-modal>
  <div class="modal-section">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span class="close-button" aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <p>
        タグ名: {{ targetTag.tag_name }} <br>
        上記のタグを削除します。<br>
        削除後は取り消しできません。削除しますか？
      </p>
      <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-dark" (click)="modal.dismiss()" ngbAutofocus>キャンセル</button>
        <button type="button" class="btn btn-primary-dark" (click)="deleteTag()">はい</button>
      </div>
    </div>
  </div>
</ng-template>
