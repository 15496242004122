import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  clickPrivacyPolicy() {
    this.router.navigate(['/privacy-policy-component']);
    window.scrollTo(0, 0);
  }

  clickTermOfService() {
    this.router.navigate(['/terms-of-service']);
    window.scrollTo(0, 0);
  }

  isLogin() {
    return !!localStorage.account;
  }
}
