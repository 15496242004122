export const environment = {
  production: true,
  staging: true
};
export const APP_KEY = 'XXXXXX';
// 環境ごとに調整
export const API_SERVER_URL = 'https://api-frt.qoala-stg.cotegg.com/';
export const SERVICE_URL = 'https://qoala-stg.cotegg.com/';
export const GA_TRACK_ID = 'UA-112028206-3';
export const FULLSTAR_EMBED_ID = '011ac1c5-bfe2-4ac5-8598-cc8ecf1f0a9c';
export const FRONT_DOMAIN = 'cotegg.com'; // v1とv2のcookieを共有するため同じドメイン想定
export const V2_FRONT_URL = 'https://qoala-v2-stg-front.cotegg.com/';
