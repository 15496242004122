import { Injectable } from '@angular/core';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends HttpClientAbstract {
  constructor(
    header: HttpHeaderService,
    http: HttpClient,
    private toastrService: ToastrService
  ) {
    super(header, http);
  }

  public getCompanyList(): Observable<any> {
    return this.requestGet('api/v1/company_list');
  }

  public getCompany(id): Observable<any> {
    return this.requestGet('api/v1/company', { id: id });
  }

  public createNewCompany(data): Observable<any> {
    return this.requestPost('api/v1/company', data);
  }
}
