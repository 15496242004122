import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVER_URL } from '../../../environments/environment';
// Rxjs
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
// model
import { LoginForm } from 'src/app/shared/model/loginForm.model';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';

import { AccountService } from 'src/app/shared/service/account.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    private header: HttpHeaderService,
    private http: HttpClient,
    private accountService: AccountService
  ) {}

  signIn(loginForm: LoginForm): Observable<any> {
    const url = API_SERVER_URL + 'api/v1/auth/sign_in';
    return this.http.post<any>(url, loginForm, this.header.getHttpOptions());
  }

  signOut(): Observable<any> {
    const url = API_SERVER_URL + 'api/v1/auth/sign_out';

    return this.http
      .delete<any>(url, this.header.getHttpOptions())
      .pipe(finalize(() => this.accountService.clear()));
  }

  passwordResetMail(email: any) {
    const url = API_SERVER_URL + 'api/v1/password/reset_mail';
    return this.http.post<any>(url, email, this.header.getHttpOptions());
  }

  passwordResetChange(password: any) {
    const url = API_SERVER_URL + 'api/v1/password/reset_change';
    return this.http.post<any>(url, password, this.header.getHttpOptions());
  }

  passwordUpdateChange(password: any) {
    const url = API_SERVER_URL + 'api/v1/password/update_change';
    return this.http.post<any>(url, password, this.header.getHttpOptions());
  }

  verifyOtp(param: any): Observable<any> {
    const url = API_SERVER_URL + 'api/v1/auth/verify_otp';
    return this.http.post<any>(url, param, this.header.getHttpOptions());
  }

  registerBrowser(param: any): Observable<any> {
    const url = API_SERVER_URL + 'api/v1/auth/register_browser';
    return this.http.post<any>(url, param, this.header.getHttpOptions());
  }

  deleteBrowser(id: number, param: any): Observable<any> {
    const url = API_SERVER_URL + `api/v1/auth/delete_browser/${id}`;
    return this.http.delete<any>(url, this.header.getHttpOptions(param));
  }
}
