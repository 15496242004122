var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClientAbstract } from './http-client-abstract';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
var FileDownloadService = /** @class */ (function (_super) {
    __extends(FileDownloadService, _super);
    function FileDownloadService(header, http) {
        return _super.call(this, header, http) || this;
    }
    FileDownloadService.prototype.saveActionLog = function (params) {
        return _super.prototype.requestPost.call(this, '/download/action_log', params);
    };
    // frontからAPIにS3オブジェクトを取得するリクエストを送る
    FileDownloadService.prototype.requestMailFile = function (params) {
        return _super.prototype.requestGetfile.call(this, '/download/mail_file_from_s3', params);
    };
    // frontからAPIにS3オブジェクトを取得するリクエストを送る
    FileDownloadService.prototype.requestSkillSheet = function (params) {
        return _super.prototype.requestGetfile.call(this, '/download/skill_sheet_from_s3', params);
    };
    // frontからAPIにS3オブジェクトの署名付きURLを取得するリクエストを送る
    FileDownloadService.prototype.requestFilePreviewUrl = function (params) {
        return _super.prototype.requestGet.call(this, '/download/file_preview_url_from_s3', params);
    };
    // frontからAPIにメール一覧CSVを作成するリクエストを送る
    FileDownloadService.prototype.requestMailsCSV = function (params) {
        return _super.prototype.requestPost.call(this, '/download/mail_index_csv_request', params);
    };
    // frontからAPIにCSVを取得するリクエストを送る
    FileDownloadService.prototype.requestDownloadCSV = function (params) {
        return _super.prototype.requestGetfile.call(this, '/download/csv_download', params);
    };
    // front assetsフォルダ内のファイルを取得するリクエストを送る
    FileDownloadService.prototype.requestDownloadAssetsFile = function (url, params) {
        var options = { responseType: 'blob' };
        return _super.prototype.requestGetAssetsFile.call(this, url, params, options);
    };
    FileDownloadService.ngInjectableDef = i0.defineInjectable({ factory: function FileDownloadService_Factory() { return new FileDownloadService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient)); }, token: FileDownloadService, providedIn: "root" });
    return FileDownloadService;
}(HttpClientAbstract));
export { FileDownloadService };
