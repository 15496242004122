import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ReportService } from '../../shared/service/report.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {
  reportUrl: SafeResourceUrl;
  message: string;
  private subscription: Subscription;

  constructor(
    private reportService: ReportService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getReport();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getReport() {
    this.subscription = this.reportService.getReport().subscribe(
      res => {
        if (res.body.url === null) {
          this.message = `
            表示できるレポートがありません。通常、レポートはご利用開始から1か月以内に自動的に生成されます。
            レポート機能についてのご要望・ご質問は info@qoala.jp までお問い合わせください。
          `;
        } else {
          this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.body.url);
        }
      },
      err => {
          this.message = `
            レポートの取得に失敗しました。ページを更新し、再度お試しください。
            それでも解決しない場合は info@qoala.jp までお問い合わせください。
          `;
      }
    );
  }
}
