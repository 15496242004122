export class Account {
  public uid: string; // 認証用
  public client: string; // 認証用
  public accessToken: string; // 認証用
  public email: string;
  public name: string;
  public auth: string;
  public id: string;
  public company_name: string;
  public user_code: string;
  public is_pilot_user: boolean;

  constructor(
    uid: string,
    client: string,
    accessToken: string,
    email: string,
    name: string,
    auth: string,
    id: string,
    company_name: string,
    user_code: string,
    is_pilot_user: boolean 
  ) {
    this.uid = uid;
    this.client = client;
    this.accessToken = accessToken;
    this.email = email;
    this.name = name;
    this.auth = auth;
    this.id = id;
    this.company_name = company_name;
    this.user_code = user_code;
    this.is_pilot_user = is_pilot_user;
  }
}
