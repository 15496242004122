import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegExpService {

  constructor() { }

  // BPメールアドレスのバリデーション（BPの登録に使うメールアドレスは「＠」以降のみでも許可）
  public validateBpMailFrom(mailFrom: string): boolean {
    // メールアドレスの正規表現
    const emailRegex = /^[a-zA-Z0-9@._!#$%&'*+-/=?^_`{|}~]+$/;
    return emailRegex.test(mailFrom);
  }
}
