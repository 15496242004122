import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modifyNewArrivalMailNumber'
})
export class ModifyNewArrivalMailNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value < 0) {
      return 0;
    } else {
      return value;
    }
  }
}
