import { OnDestroy, OnInit } from '@angular/core';
import { AdminMessageService } from 'src/app/shared/service/admin-message.service';
var AdminMessageBoxComponent = /** @class */ (function () {
    function AdminMessageBoxComponent(adminMessageService) {
        this.adminMessageService = adminMessageService;
        this.messages = [];
        this.alreadyReadIds = [];
        this.unreadNotification = false;
    }
    AdminMessageBoxComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.messageSubscription = this.adminMessageService.adminMessages$.subscribe(function (adminMessages) {
            var _a;
            _this.messages = adminMessages.messages;
            _this.alreadyReadIds = Array.from(new Set((_a = _this.alreadyReadIds).concat.apply(_a, adminMessages.already_read_ids)));
            var flag = false;
            _this.messages.map(function (m) {
                if (!_this.alreadyReadIds.includes(m.id)) {
                    flag = true;
                }
            });
            _this.unreadNotification = flag;
        });
    };
    AdminMessageBoxComponent.prototype.ngOnDestroy = function () {
        this.messageSubscription.unsubscribe();
    };
    AdminMessageBoxComponent.prototype.messageChecked = function (open) {
        var _this = this;
        if (this.unreadNotification && !open) {
            var checked_message = this.messages.filter(function (m) { return !_this.alreadyReadIds.includes(m.id); });
            var checked_message_ids = checked_message.map(function (m) {
                _this.alreadyReadIds.push(m.id);
                return m.id;
            });
            this.unreadNotification = false;
            this.adminMessageService.messageChecked(checked_message_ids);
        }
    };
    return AdminMessageBoxComponent;
}());
export { AdminMessageBoxComponent };
