import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminMessage } from 'src/app/shared/model/admin-message.model';
import { AdminMessageService } from 'src/app/shared/service/admin-message.service';

@Component({
  selector: 'app-admin-message-box',
  templateUrl: './admin-message-box.component.html',
  styleUrls: ['./admin-message-box.component.scss']
})
export class AdminMessageBoxComponent implements OnInit, OnDestroy {
  messages: AdminMessage[] = [];
  alreadyReadIds: number[] = [];
  unreadNotification = false;
  messageSubscription: Subscription;

  constructor(private adminMessageService: AdminMessageService) {}

  ngOnInit() {
    this.messageSubscription = this.adminMessageService.adminMessages$.subscribe(
      adminMessages => {
        this.messages = adminMessages.messages;

        this.alreadyReadIds = Array.from(
          new Set(this.alreadyReadIds.concat(...adminMessages.already_read_ids))
        );
        let flag = false;
        this.messages.map(m => {
          if (!this.alreadyReadIds.includes(m.id)) {
            flag = true;
          }
        });
        this.unreadNotification = flag;
      }
    );
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }

  messageChecked(open: boolean) {
    if (this.unreadNotification && !open) {
      const checked_message = this.messages.filter(
        m => !this.alreadyReadIds.includes(m.id)
      );
      const checked_message_ids = checked_message.map(m => {
        this.alreadyReadIds.push(m.id);
        return m.id;
      });
      this.unreadNotification = false;
      this.adminMessageService.messageChecked(checked_message_ids);
    }
  }
}
