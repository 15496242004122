import { SearchPage } from 'src/app/shared/model/search-page.model';
import { SearchCount } from 'src/app/shared/model/search-count.model';

export class PageInfo {
  public searchCount: SearchCount;
  public searchPage: SearchPage;

  constructor(
    searchCount: SearchCount = new SearchCount(),
    searchPage: SearchPage = new SearchPage()
  ) {
    this.searchCount = searchCount;
    this.searchPage = searchPage;
  }
  getStartCount(): number {
    return this.searchCount.startCount;
  }
  getEndCount(): number {
    return this.searchCount.endCount;
  }
  getTotalCount(): number {
    return this.searchCount.totalCount;
  }
  getPerPage(): number {
    return this.searchCount.perPage;
  }
  getPage(): number {
    return this.searchPage.page;
  }
}
