import { Injectable } from '@angular/core';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorFeedbackService extends HttpClientAbstract {
  constructor(header: HttpHeaderService, http: HttpClient) {
    super(header, http);
  }

  public getActionLogIdForCorrectingMailInfo(params: any): Observable<any> {
    return super.requestPost(
      'api/v1/correcting_mail_info/get/action_log_id',
      params
    );
  }

  public sendCorrectedMailInfo(params: any): Observable<any> {
    return super.requestPost(
      'api/v1/correcting_mail_info/correct/mail_info',
      params
    );
  }

  public getErrorFeedbackInfo(params: any): Observable<any> {
    return super.requestGet(
      'api/v1/error_feedback/get_error_feedback_info',
      params
    );
  }

  public saveErrorFeedbackInfo(params: any): Observable<any> {
    return super.requestPost(
      'api/v1/error_feedback/save_error_feedback_info',
      params
    );
  }
}
