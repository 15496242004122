import { SearchService } from '../service/search/search.service';

export class SearchCondition {
  public id: number;
  public user_id: number;
  public mail_type: string;
  public keyword = '';
  public required_skills = '';
  public fee_from = '';
  public fee_to = '';
  public fee_null = true;
  public age_from = '';
  public age_to = '';
  public age_null = true;
  public work_location = '';
  public commute_location = '';
  public commute_time = null;
  public mail_from = '';
  public bp_rank = '0';
  public number_of_connect_company = '';
  public number_of_connect_company_null = true;
  public employment_status = '0';
  public employment_status_null = true;
  public interview_times = '0';
  public interview_times_null = true;
  public country_of_citizenship = '0';
  public country_of_citizenship_null = true;
  public work_immidiately = '0';
  public work_immidiately_null = true;
  public begin_from: string = null;
  public begin_to: string = null;
  public begin_null = true;
  public receipt_datetime = '0';
  public belongs: string;
  public active_tab_id: string;
  public matching_filters = [];
  public gross_margin_setting: number;
  public mail_custom_tag_ids: number[] = [];
  public sender_hash_id: string;

  constructor(hash: any) {
    if (Object.keys(hash).length) {
      this.id = hash.id || this.id;
      this.user_id = hash.user_id || this.user_id;
      this.mail_type = hash.mail_type || this.mail_type;
      this.keyword = hash.keyword || this.keyword;
      this.required_skills = hash.required_skills || this.required_skills;
      this.fee_from = hash.fee_from || this.fee_from;
      this.fee_to = hash.fee_to || this.fee_to;
      this.fee_null =
        hash.fee_null === undefined ? this.fee_null : hash.fee_null;
      this.age_from = hash.age_from || this.age_from;
      this.age_to = hash.age_to || this.age_to;
      this.age_null =
        hash.age_null === undefined ? this.age_null : hash.age_null;
      this.work_location = hash.work_location || this.work_location;
      this.commute_location = hash.commute_location || this.commute_location;
      this.commute_time = hash.commute_time || this.commute_time;
      this.mail_from = hash.mail_from || this.mail_from;
      this.bp_rank = hash.bp_rank || this.bp_rank;
      this.number_of_connect_company =
        hash.number_of_connect_company || this.number_of_connect_company;
      this.number_of_connect_company_null =
        hash.number_of_connect_company_null === undefined
          ? this.number_of_connect_company_null
          : hash.number_of_connect_company_null;
      this.employment_status = hash.employment_status || this.employment_status;
      this.employment_status_null =
        hash.employment_status_null === undefined
          ? this.employment_status_null
          : hash.employment_status_null;
      this.interview_times = hash.interview_times || this.interview_times;
      this.interview_times_null =
        hash.interview_times_null === undefined
          ? this.interview_times_null
          : hash.interview_times_null;
      this.country_of_citizenship =
        hash.country_of_citizenship || this.country_of_citizenship;
      this.country_of_citizenship_null =
        hash.country_of_citizenship_null === undefined
          ? this.country_of_citizenship_null
          : hash.country_of_citizenship_null;
      this.work_immidiately = hash.work_immidiately || this.work_immidiately;
      this.work_immidiately_null =
        hash.work_immidiately_null === undefined
          ? this.work_immidiately_null
          : hash.work_immidiately_null;
      this.receipt_datetime = hash.receipt_datetime || this.receipt_datetime;
      this.belongs = hash.belongs || this.belongs;
      this.active_tab_id = hash.active_tab_id || this.active_tab_id;
      this.matching_filters = hash.matching_filters || this.matching_filters;
      this.gross_margin_setting =
        hash.gross_margin_setting || this.gross_margin_setting;
      this.mail_custom_tag_ids =
        hash.mail_custom_tag_ids || this.mail_custom_tag_ids;
      this.begin_from = hash.begin_from || this.begin_from;
      this.begin_to = hash.begin_to || this.begin_to;
      this.begin_null =
        hash.begin_null === undefined ? this.begin_null : hash.begin_null;
      this.sender_hash_id = hash.sender_hash_id || this.sender_hash_id;
    }
  }
}
