// Module
import { AppRoutingModule } from './app-routing.module';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { GestureConfig, MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE  } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
// Component
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthGuardService } from './shared/service/auth-guard.service';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponentComponent } from './pages/privacy-policy-component/privacy-policy-component.component';
import { LeaveOnlyImportantLinePipe } from './shared/pipe/leave-only-important-line.pipe';
import { MailQuotesPipe } from './shared/pipe/mail-quotes.pipe';
import { Ng2CompleterModule } from 'ng2-completer';
import { RegistrationComponent } from './pages/registration/registration.component';
import { AdminMessagePublisherComponent } from './pages/registration/admin-message-publisher/admin-message-publisher.component';
import { ReportComponent } from './pages/report/report.component';
import { FileDownloadComponent } from './pages/file-download/file-download.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationInterceptor } from './shared/interceptor/authorization.interceptor';
import { ModifyEmploymentStatusTextForProjectPipe } from './shared/pipe/modify-employment-status-text-for-project.pipe';
import { ModifyEmploymentStatusTextForWorkerPipe } from './shared/pipe/modify-employment-status-text-for-worker.pipe';
import { TranslateCountryOfCitizenshipTextPipe } from './shared/pipe/translate-country-of-citizenship-text.pipe';
import { TranslateSearchConditionPipe } from './shared/pipe/translate-search-condition.pipe';

// datepicker
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomDateAdapter, MY_DATE_FORMATS } from './shared/datepicker/custom-date-adapter';

@NgModule({
  declarations: [
    AppComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponentComponent,
    RegistrationComponent,
    AdminMessagePublisherComponent,
    ReportComponent,
    FileDownloadComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2CompleterModule,
    HttpClientModule,
    NgbModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    CoreModule,
    MatDatepickerModule,
    MatInputModule
  ],
  providers: [
    AuthGuardService,
    LeaveOnlyImportantLinePipe,
    MailQuotesPipe,
    ModifyEmploymentStatusTextForWorkerPipe,
    ModifyEmploymentStatusTextForProjectPipe,
    TranslateCountryOfCitizenshipTextPipe,
    TranslateSearchConditionPipe,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
