var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClientAbstract } from './http-client-abstract';
import { HttpHeaderService } from './http-header.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
var ConfirmMailTemplateService = /** @class */ (function (_super) {
    __extends(ConfirmMailTemplateService, _super);
    function ConfirmMailTemplateService(header, http) {
        return _super.call(this, header, http) || this;
    }
    ConfirmMailTemplateService.prototype.getMailTemplate = function () {
        return _super.prototype.requestGet.call(this, 'api/v1/confirm_mail_template');
    };
    ConfirmMailTemplateService.prototype.activateMailTemplate = function (isActivated) {
        var params = { activate: isActivated };
        return _super.prototype.requestPost.call(this, 'api/v1/confirm_mail_template/activate', params);
    };
    ConfirmMailTemplateService.prototype.saveMailTemplate = function (template) {
        var params = { mail_template: template };
        return _super.prototype.requestPost.call(this, 'api/v1/confirm_mail_template/create', params);
    };
    ConfirmMailTemplateService.ngInjectableDef = i0.defineInjectable({ factory: function ConfirmMailTemplateService_Factory() { return new ConfirmMailTemplateService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient)); }, token: ConfirmMailTemplateService, providedIn: "root" });
    return ConfirmMailTemplateService;
}(HttpClientAbstract));
export { ConfirmMailTemplateService };
