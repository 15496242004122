/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./footer.component";
import * as i4 from "@angular/router";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "footer-basic footer-style"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "social"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "ul", [["class", "list-inline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "li", [["class", "list-inline-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickTermOfService() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u5229\u7528\u898F\u7D04"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "li", [["class", "list-inline-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickPrivacyPolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30D7\u30E9\u30A4\u30D1\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "li", [["class", "list-inline-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["href", "https://lp.qoala.jp/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Qoala\u306B\u3064\u3044\u3066"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "li", [["class", "list-inline-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "a", [["href", "https://www.riff.co.jp/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u904B\u55B6\u4F1A\u793E\u306B\u3064\u3044\u3066"])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "li", [["class", "list-inline-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["href", "https://qoala.jp/contact/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u304A\u554F\u3044\u5408\u308F\u305B"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "p", [["class", "copyright copyright-style"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u24B8 Riff,Inc. All Right Reserved. "]))], null, null); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLogin(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i3.FooterComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
