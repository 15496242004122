<div class="modal-section">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span class="close-button" aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>
      {{ targetServiceName }}に公開すると、登録した情報のうち「件名＋本文」が他の{{ targetServiceName }}ユーザーに表示されます。個人情報など公開すべきではない情報が記載されていないか、ご注意ください。
    </p>
    <p>
      また公開後は、非公開に変更してもすぐに{{ targetServiceName }}からは削除されず、一定期間後に非表示になります。<br>
      特段の事情により緊急で削除したい場合は、<a href="mailto:info@qoala.jp?subject={{ targetServiceName }}に関する問い合わせ">info@qoala.jp</a> までご連絡ください。
    </p>
    <p>上記の注意事項を確認の上、{{ targetServiceName }}に公開しますか？</p>

    <div class="custom-checkbox text-center">
      <input
        type="checkbox" class="custom-control-input" id="hideModalBtn"
        [(ngModel)]="hideModal" >
      <label class="custom-control-label" for="hideModalBtn">この確認画面をしばらく表示しない</label>
    </div>

    <div class="d-flex justify-content-between">
      <button type="button" class="btn btn-dark" (click)="activeModal.dismiss()" ngbAutofocus>キャンセル</button>
      <button type="button" class="btn btn-primary-dark" (click)="confirm()">はい</button>
    </div>
  </div>
</div>
