<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span class="close-button" aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="anotation error-message" *ngIf="errorMessage">
    エラー報告する項目の選択をお願いします。
  </p>
  <ng-container *ngFor="let errorFeedbackInfo of errorFeedbackInfos; let i = index">
    <div class="item-box" [ngClass]="{
        'child-item-box': !!errorFeedbackInfo.error_feedback_item.parent_item_id
      }">
      <div *ngIf="errorFeedbackInfo.error_feedback_item.format_type === 'checkbox'">
        <!-- マッチング候補のみcalcAisに関するエラー報告を表示 -->
        <div *ngIf="
            errorFeedbackInfo.error_feedback_item
              .table_to_delete_record_from === 'mail' || matchingScoreId
          ">
          <label for="">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" id="customCheck{{ i }}"
                type="{{ errorFeedbackInfo.error_feedback_item.format_type }}" name="{{ errorFeedbackInfo.error_feedback_item.format_type }}{{
                  errorFeedbackInfo.error_feedback_item.id
                }}" [checked]="
                  errorFeedbackInfo.error_feedback.format_choice_answer
                " (change)="
                  errorFeedbackInfo.error_feedback.format_choice_answer = !errorFeedbackInfo
                    .error_feedback.format_choice_answer
                " />
              <label class="custom-control-label" for="customCheck{{ i }}">{{
                errorFeedbackInfo.error_feedback_item.text
              }}</label>
              <ng-container *ngIf="
                  errorFeedbackInfo.error_feedback_item
                    .table_to_delete_record_from === 'matching_score'
                ">
                &nbsp;<i class="fa fa-question-circle" ngbTooltip="以後、候補として表示されなくなります。一覧画面や他のメールの候補としては引き続き表示されます。"></i>
              </ng-container>
              <ng-container *ngIf="
                  errorFeedbackInfo.error_feedback_item
                    .table_to_delete_record_from === 'mail' &&
                  !errorFeedbackInfo.error_feedback_item.parent_item_id
                ">
                &nbsp;<i class="fa fa-question-circle" ngbTooltip="以後、候補としてだけでなく、一覧画面でも一切表示されなくなります。"></i>
              </ng-container>
            </div>
          </label>
          <br />
        </div>
      </div>
      <div class="text-item-box" *ngIf="errorFeedbackInfo.error_feedback_item.format_type === 'text'">
        <div class="form-group">
          <p>Qoala運営チームへの報告</p>
          <textarea [(ngModel)]="errorFeedbackInfo.error_feedback.format_text_answer" class="form-control"
            style="height: 85px;" rows="5" name="{{ errorFeedbackInfo.error_feedback_item.format_type }}"
            placeholder="お寄せいただいた情報はサービス改善に活用させていただきます。&#13;&#10;ご協力のほど、どうぞよろしくお願いいたします。">
          </textarea>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">
    キャンセル
  </button>
  <button (click)="sendErrorFeedback()" class="btn btn-danger" type="button">
    削除
  </button>
</div>
