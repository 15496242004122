export class MailCustomTag {
  id = -1;
  tag_name = '';
  mail_tagging_id = -1;
  constructor(hash: any) {
    if (hash) {
      for (const key of Object.keys(this)) {
        this[key] =
          hash[key] === null || hash[key] === undefined ? this[key] : hash[key];
      }
    }
  }
}
