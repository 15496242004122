import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatchingOffer } from '../../../model/matching-offer.model';
@Component({
  selector: 'app-mailer',
  templateUrl: './mailer.component.html',
  styleUrls: ['./mailer.component.scss']
})
export class MailerComponent implements OnInit {
  @Input() mailHref: string;
  @Input() mailTo: string;
  @Input() mailCc: string;
  @Input() mailSubject: string;
  @Input() matchingOffer: MatchingOffer;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  openMailer(): void {
    window.open(this.mailHref, '_blank');
    this.activeModal.close(this.matchingOffer);
  }
}
