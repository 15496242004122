import { finalize, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService } from 'src/app/shared/service/account.service';
import { ToastrService } from 'ngx-toastr';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private accountService: AccountService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // extend server response observable with logging
    return next.handle(req).pipe(
      tap(
        // response成功した場合，何もしない
        _ => {},
        error => {
          switch (error.status) {
            case 401: {
              const isEnabledV2Authentication = document.cookie
                .split(';')
                .some(item => item.trim().startsWith('v2_auth_data='));

              // v2ログアウトの場合（v2ログアウト時にv2認証用クッキーが削除される想定）
              if (!!localStorage.account && !isEnabledV2Authentication) {
                this.toastrService.success('ログアウトしました')
              } else if (!!localStorage.account) {
                  this.toastrService.error('ログインセッションが切れました');
              }
              this.accountService.clear();
              this.router.navigate(['/login']).then(
                () => {},
                onreject => {
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              );
            }
          }
        }
      )
    );
  }
}
