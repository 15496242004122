import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modifyEmploymentStatusTextForWorker'
})
export class ModifyEmploymentStatusTextForWorkerPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 'direct_agreement':
      case 'contract_employee':
        return '社員';
      case 'solo_worker':
      case 'freelance':
        return '個人';
      default:
        return null;
    }
  }

  transformForCondition(value: any, args?: any): any {
    switch (value) {
      case 'solo_worker':
      case 'freelance':
        return '2';
      default:
        return null;
    }
  }
}
