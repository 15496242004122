import { GA_TRACK_ID } from 'src/environments/environment';
import { AccountService } from 'src/app/shared/service/account.service';
import * as i0 from "@angular/core";
import * as i1 from "./account.service";
var GoogleAnalysticsService = /** @class */ (function () {
    function GoogleAnalysticsService(accountService) {
        this.accountService = accountService;
        gtag('config', GA_TRACK_ID, {
            custom_map: {
                dimension1: '案件ID',
                dimension2: '人材ID',
                dimension3: 'イベント'
            }
        });
        // ログイン済みの場合のみユーザーIDを送信する
        if (this.isLogin()) {
            gtag('config', GA_TRACK_ID, {
                user_id: this.accountService.getUid(),
            });
        }
    }
    GoogleAnalysticsService.prototype.pageView = function (link) {
        gtag('event', 'page_view', { page_path: link });
    };
    GoogleAnalysticsService.prototype.eventEmit = function (GAevent, param_1, param_2, value) {
        gtag('event', String(param_1), {
            event_category: String(GAevent),
            event_label: String(param_2),
            value: value
        });
        gtag('event', GAevent + 'dimension', {
            案件ID: param_1,
            人材ID: param_2,
            イベント: GAevent
        });
        //      event                       param_1      param_2
        // P列　元メールDL数　　　　　　　　　どのページで　メールID
        // Q列　スキルシートDL数　　　　　　　どのページで　メールIDのスキルシートがDL
        // R列　提案管理ボタンが押された回数　案件ID　　　　人材ID
        // S列　案件確認ボタンが押された回数　案件ID　　　　人材ID
        // T列　案件承諾に至った回数　　　　　案件ID　　　　人材ID
        // U列　人材確認ボタンが押された回数　案件ID　　　　人材ID
        // V列　人材承諾に至った回数　　　　　案件ID　　　　人材ID
    };
    GoogleAnalysticsService.prototype.isLogin = function () {
        return !!localStorage.account;
    };
    GoogleAnalysticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalysticsService_Factory() { return new GoogleAnalysticsService(i0.inject(i1.AccountService)); }, token: GoogleAnalysticsService, providedIn: "root" });
    return GoogleAnalysticsService;
}());
export { GoogleAnalysticsService };
