import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVER_URL as STG_API_URL } from 'src/environments/environment.stg';
import { Observable, Subscription } from 'rxjs';

import { LoginForm } from 'src/app/shared/model/loginForm.model';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from './account.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SyncToStgService implements OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private header: HttpHeaderService,
    private http: HttpClient,
    private toastrService: ToastrService,
    private accountService: AccountService
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  requestGet_STG(requestDestination: string, params?: any): Observable<any> {
    const url = STG_API_URL + requestDestination;
    return this.http.get<any>(url, this.header.getSTGHttpOptions(params));
  }

  requestPost_STG(
    requestDestination: string,
    params?: object
  ): Observable<any> {
    const url = STG_API_URL + requestDestination;
    return this.http.post<any>(url, params, this.header.getSTGHttpOptions());
  }

  signIn(loginForm: LoginForm): void {
    const param = {
      ...loginForm,
      sync_to_stg: true
    };
    if (this.needSync()) {
      const signInSub = this.requestPost_STG('api/v1/auth/sign_in', param).subscribe(
        res => {
          // Headerの情報を取得、sessionにトークン設定
          this.accountService.setSTGAccount(res);
          this.toastrService.success('Syncログインに成功しました');
        },
        error => {
          this.toastrService.error('Syncログインに失敗しました');
        }
      );
      this.subscriptions.add(signInSub);
    }
  }

  public getCompanyList(): Observable<any> {
    return this.requestGet_STG('api/v1/company_list');
  }

  public getCompany(id): Observable<any> {
    return this.requestGet_STG('api/v1/company', { id: id });
  }

  public createNewCompany(data): Observable<any> {
    return this.requestPost_STG('api/v1/company', data);
  }

  checkSyncSignIn(): boolean {
    return localStorage.sync_to_stg_account ? true : false;
  }

  public needSync(): boolean {
    return environment.production && !environment.staging;
  }
}
