import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OfferService } from 'src/app/shared/service/offer.service';
import { ToastrService } from 'ngx-toastr';
import { MatchingOffer } from '../../model/matching-offer.model';
import { PrepareTextService } from '../../service/prepare-text.service';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';
import { GoogleAnalysticsService } from 'src/app/shared/service/google-analystics.service';

@Component({
  selector: 'app-confirmation-request',
  templateUrl: './confirmation-request.component.html',
  styleUrls: ['./confirmation-request.component.scss']
})
export class ConfirmationRequestComponent implements OnInit, OnDestroy {
  @Input() offerId;
  @Input() offerType; // 1:案件確認依頼, 2:人材確認依頼
  @Input() projectMailId;
  @Input() workerMailId;
  @Input() side; // ボタンが存在するページ
  @Input() actionLogId;
  @Input() matchingOffer: MatchingOffer;
  @Input() projectAIMailId: string;
  @Input() workerAIMailId: string;

  offerTypeStr: string;
  mailSubject = 'Re:';
  mailTo: string;
  mailCc: string;
  mailHref: string;
  progressStatus = 'considering';
  private subscriptions: Subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private offerService: OfferService,
    private toastrService: ToastrService,
    private prepareTextService: PrepareTextService,
    private clipboardService: ClipboardService,
    private googleAnalysticsService: GoogleAnalysticsService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.request();
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  request() {
    const params = this.makeRequestParams();
    let GAevent = this.offerType === 'project' ? '案件確認' : '人材確認';
    let successMessage = this.offerType === 'project' ? 'オファーステータスを案件確認中に設定しました' : 'オファーステータスを人材確認中に設定しました';
    this.toastrService.success(successMessage);

    // 先にユーザーにクリップボードコピーを知らせて待機させる
    this.toastrService.info('クリップボードにメールの本文をコピーします。少々お待ちください');

    const offer$ = this.offerType === 'project' ? this.offerService.confirmProject(params) : this.offerService.confirmWorker(params);

    const sub = offer$.subscribe(res => {
      if (!this.offerId) {
        this.offerId = res.body.matching_offer.id;
      }
      this.matchingOffer = res.body.matching_offer;

      const offerSub = this.offerService.getOfferInfo(this.offerId, this.offerType)
        .subscribe(result => {
          const mail = this.offerType === 'project' ? result.body.project_mail : result.body.worker_mail;
          this.makeMailHref(mail);
          const mailTextSub = this.prepareTextService.setApproveMailText(result, this.offerType)
            .subscribe(body => {
              this.copyToClipboard(body);
              this.googleAnalysticsService.eventEmit(GAevent, this.projectAIMailId, this.workerAIMailId, 0);
            });
          this.subscriptions.add(mailTextSub);
        });
      this.subscriptions.add(offerSub);
    });
    this.subscriptions.add(sub);
  }

  // 引数に渡された文字列をクリップボードにコピー
  private copyToClipboard(body: string): void {
    if (this.clipboardService.copyFromContent(body)) {
      this.toastrService.warning('クリップボードにメールの本文をコピーしました。金額情報にご注意ください。');
    } else {
      this.toastrService.error('クリップボードへのコピーに失敗しました。再度お試しください。');
    }
  }

  // ステータス更新に必要なパラメータの作成
  private makeRequestParams() {
    return {
      offer_id: this.offerId,
      project_mail_id: this.projectMailId,
      worker_mail_id: this.workerMailId,
      offer_side: this.offerType,
      action_log_id: this.actionLogId,
      action_log_type: this.side
    };
  }

  // メーラーに入れる件名と送信先を変数に代入とメーラ起動用リンク作成
  private makeMailHref(mail: any): void {
    this.mailSubject += mail.title;
    this.mailTo = mail.mail_from;
    this.mailCc = mail.mail_cc;
    this.offerTypeStr = this.offerType === 'project' ? '案件' : '人材';
    this.mailHref = `mailto:${this.mailTo}?subject=${this.mailSubject}${this.mailCc ? `&cc=${this.mailCc}` : ''}`;
  }
}
