import { MailInfo } from './../model/mail-info.model';
import { Pipe, PipeTransform } from '@angular/core';
import { StringService } from '../service/string.service';

@Pipe({
  name: 'setBodyString'
})
export class SetBodyStringPipe implements PipeTransform {

  constructor( private stringService: StringService ) {}

  // body_jsonからメール本文を成形する関数
  // body_jsonの単語を連結させた後に、行を連結させる
  transform(mail: MailInfo, keywords: object, baseKeywords: string[]): any {

    // make deep copy to prevent ExpressionChangedAfterItHasBeenCheckedError
    const decorated_mail = JSON.parse(JSON.stringify(mail));
    if (mail.body_json) {
      const bodyJson = JSON.parse(mail.body_json);
      // body_jsonのループ処理
      for (const lineKey in bodyJson) {
        if (bodyJson.hasOwnProperty(lineKey)) {
          // body_jsonの各行のループ処理
          for (const wordKey in bodyJson[lineKey]) {
            // json構造 {行番号: {単語番号: [表示する単語名, 意味語, 重要度], ..}}
            let vocabularyWord = this.stringService.checkKeywordDecoration(
              mail.matched_skills,
              baseKeywords,
              bodyJson[lineKey][wordKey][0],
              bodyJson[lineKey][wordKey][1],
              bodyJson[lineKey][wordKey][2]
            )
            bodyJson[lineKey][wordKey][0] = vocabularyWord
          }
        }
      }
      // 太字化・ハイライト・オレンジ化などの装飾を施したjsonに置き換える
      decorated_mail.body_json = JSON.stringify(bodyJson);
    }

    let bodyStr: string;
    if (decorated_mail.body_json) {
      let body = '';
      const bodyValues: any = Object.values(JSON.parse(decorated_mail.body_json));
      bodyValues.forEach(el => {
        let line = '';
        for (const key in el) {
          if (el.hasOwnProperty(key)) {
            line += el[key][0];
          }
        }
        line += '\n';
        body += line;
      });
      bodyStr = body;
    } else {
      bodyStr = mail.body;
    }
    return bodyStr;
  }
}
