import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-open-to-service',
  templateUrl: './confirm-open-to-service.component.html',
  styleUrls: ['./confirm-open-to-service.component.scss']
})
export class ConfirmOpenToServiceComponent implements OnInit {
  @Input() targetServiceName: string;

  hideModal: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  confirm() {
    this.activeModal.close({ hideModal: this.hideModal });
  }
}
