import { DetailPageInfo } from './../../model/deail-page-info.model';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
// model
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { SearchService } from 'src/app/shared/service/search/search.service';
import { tap, map } from 'rxjs/operators';
import { SearchPage } from '../../model/search-page.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SearchProjectWorkerService extends SearchService implements OnDestroy {
  // サービス毎に初期値を設定する
  initPage = { page: 1 };
  initPerPage = { perPage: 10 };
  selfServiceName = 'SearchProjectWorkerService';

  constructor(
    header: HttpHeaderService,
    http: HttpClient,
    private router: Router
  ) {
    super(header, http);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  doSearch(id: string, detailPageInfo?: DetailPageInfo): Observable<any> {
    this.setSearchPage(new SearchPage({ page: 1 }));
    detailPageInfo = new DetailPageInfo(detailPageInfo);
    return super.requestPost(
      'api/v1/projects/' + id + '/workers',
      this.getSearchParameters(detailPageInfo.getParams())
    );
  }

  showProjectMail(id: string): Observable<any> {
    return super.requestGet('api/v1/projects/' + id);
  }

  getTransitionPage(id: string, direction: string): Observable<any> {
    const params = { id: id, direction: direction };
    return super.requestGet('api/v1/projects/page_transition', params);
  }

  transitionPage(id: string, direction: string): void {
    let observable: Observable<string>;
    if (sessionStorage.projectIds) {
      observable = new Observable(observer => {
        observer.next(this.getNextmailIdFromSession(id, direction));
      });
    } else {
      observable = this.getTransitionPage(id, direction).pipe(
        map(res => res.body.project.id)
      );
    }
    const transitionSub = observable.subscribe(mailId => {
      if (mailId) {
        this.router.navigate(['/matching/projects/' + mailId]);
      }
    });
    this.subscriptions.add(transitionSub);
  }

  private getNextmailIdFromSession(id: string, direction: string): string {
    let nexttMailId: string;
    const projectIds = sessionStorage.projectIds.split(',');
    const indexOfCurrentMail = projectIds.indexOf(id);
    if (direction === 'next') {
      nexttMailId = projectIds[indexOfCurrentMail + 1];
    } else {
      nexttMailId = projectIds[indexOfCurrentMail - 1];
    }
    return nexttMailId;
  }
}
