import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClientAbstract } from './http-client-abstract';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService extends HttpClientAbstract {
  constructor(header: HttpHeaderService, http: HttpClient) {
    super(header, http);
  }

  saveActionLog(params): Observable<any> {
    return super.requestPost('/download/action_log', params);
  }

  // frontからAPIにS3オブジェクトを取得するリクエストを送る
  requestMailFile(params): Observable<any> {
    return super.requestGetfile('/download/mail_file_from_s3', params);
  }
  // frontからAPIにS3オブジェクトを取得するリクエストを送る
  requestSkillSheet(params): Observable<any> {
    return super.requestGetfile('/download/skill_sheet_from_s3', params);
  }
  // frontからAPIにS3オブジェクトの署名付きURLを取得するリクエストを送る
  requestFilePreviewUrl(params): Observable<any> {
    return super.requestGet('/download/file_preview_url_from_s3', params);
  }
  // frontからAPIにメール一覧CSVを作成するリクエストを送る
  requestMailsCSV(params): Observable<any> {
    return super.requestPost('/download/mail_index_csv_request', params);
  }
  // frontからAPIにCSVを取得するリクエストを送る
  requestDownloadCSV(params): Observable<any> {
    return super.requestGetfile('/download/csv_download', params);
  }
  // front assetsフォルダ内のファイルを取得するリクエストを送る
  requestDownloadAssetsFile(url: string, params?: any): Observable<any> {
    const options = { responseType: 'blob' }
    return super.requestGetAssetsFile(url, params, options);
  }
}
