/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./theme-switcher.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./theme-switcher.component";
import * as i4 from "../../service/account.service";
var styles_ThemeSwitcherComponent = [i0.styles];
var RenderType_ThemeSwitcherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThemeSwitcherComponent, data: {} });
export { RenderType_ThemeSwitcherComponent as RenderType_ThemeSwitcherComponent };
function View_ThemeSwitcherComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-sun-o"]], null, null, null, null, null))], null, null); }
function View_ThemeSwitcherComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-moon-o"]], null, null, null, null, null))], null, null); }
export function View_ThemeSwitcherComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchTheme() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThemeSwitcherComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThemeSwitcherComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accountService.themeMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.accountService.themeMode; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ThemeSwitcherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-theme-switcher", [], null, null, null, View_ThemeSwitcherComponent_0, RenderType_ThemeSwitcherComponent)), i1.ɵdid(1, 114688, null, 0, i3.ThemeSwitcherComponent, [i4.AccountService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThemeSwitcherComponentNgFactory = i1.ɵccf("app-theme-switcher", i3.ThemeSwitcherComponent, View_ThemeSwitcherComponent_Host_0, {}, {}, []);
export { ThemeSwitcherComponentNgFactory as ThemeSwitcherComponentNgFactory };
