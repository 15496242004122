import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leaveOnlyImportantLine'
})
export class LeaveOnlyImportantLinePipe implements PipeTransform {
  transform(value: any, mail: any, enable: boolean = true): any {
    if (enable) {
      const importantLineJudge = JSON.parse(mail.important_line_judge);
      const importantLineFlag = 1;
      // 重要行以外に削除フラグを立てる処理。一度フラグを立てないと行がずれるためとりあえず。よくわからないけど、booleanではなく、6とか入っていた。
      // objectに対するfor inを逆から処理できるやり方があったらそっちの方がいいかも。
      for (const lineNumberString in importantLineJudge) {
        if (importantLineJudge.hasOwnProperty(lineNumberString)) {
          let lineNumber = Number(lineNumberString) + 1;
          if (importantLineJudge[lineNumberString] < importantLineFlag) {
            value = value.replace(/.*\r?\n/g, function(match) {
              if (--lineNumber === 0) {
                return 'lineDeleteFlag\n';
              } else {
                return match;
              }
            });
          }
        }
      }
      // 重要行以外のフラグ一気に全削除。
      value = value.replace(/lineDeleteFlag\n/g, '');
    }
    return value;
  }
}
