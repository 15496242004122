import * as i0 from "@angular/core";
var RegExpService = /** @class */ (function () {
    function RegExpService() {
    }
    // BPメールアドレスのバリデーション（BPの登録に使うメールアドレスは「＠」以降のみでも許可）
    RegExpService.prototype.validateBpMailFrom = function (mailFrom) {
        // メールアドレスの正規表現
        var emailRegex = /^[a-zA-Z0-9@._!#$%&'*+-/=?^_`{|}~]+$/;
        return emailRegex.test(mailFrom);
    };
    RegExpService.ngInjectableDef = i0.defineInjectable({ factory: function RegExpService_Factory() { return new RegExpService(); }, token: RegExpService, providedIn: "root" });
    return RegExpService;
}());
export { RegExpService };
