import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { BeforeLoginLogoComponent } from './before-login-logo/before-login-logo.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SearchSectionComponent } from './search-section/search-section.component';
import { AdminMessageBoxComponent } from './header/admin-message-box/admin-message-box.component';

// prettier-ignore
@NgModule({
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    RouterModule,
    SharedModule,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    BeforeLoginLogoComponent,
    SearchSectionComponent,
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    BeforeLoginLogoComponent,
    SearchSectionComponent,
    AdminMessageBoxComponent,
  ]
})
export class CoreModule { }
