var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import { NewMailsIds, NewMailsCounts } from '../model/new-received-mails';
import { AdminMessageService } from './admin-message.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./admin-message.service";
var NewReceivedMailService = /** @class */ (function (_super) {
    __extends(NewReceivedMailService, _super);
    function NewReceivedMailService(header, http, adminMessageService) {
        var _this = _super.call(this, header, http) || this;
        _this.subscriptions = new Subscription();
        _this.newReceivedNumber = new Subject();
        _this.newReceivedState = _this.newReceivedNumber.asObservable();
        _this.newMailsIds = new NewMailsIds(JSON.parse(localStorage.getItem('message_count_ids')) || {});
        _this.newMailsCounts = new NewMailsCounts({});
        var adminMessageSub = adminMessageService.newReceivedCount$.subscribe(function (res) {
            if (res) {
                _this.updateData(res.counts, res.ids);
            }
        });
        _this.subscriptions.add(adminMessageSub);
        return _this;
    }
    NewReceivedMailService.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    NewReceivedMailService.prototype.updateData = function (newMailsCounts, newMailsIds) {
        this.newMailsCounts = newMailsCounts;
        this.newMailsIds = newMailsIds;
        localStorage.setItem('message_count_ids', JSON.stringify(this.newMailsIds));
        this.newReceivedNumber.next(this.newMailsCounts);
    };
    NewReceivedMailService.prototype.initCount = function (name) {
        switch (name) {
            case 'projects':
                this.newMailsCounts.new_projects_count = 0;
                this.newMailsIds.project_id = 0;
                break;
            case 'workers':
                this.newMailsCounts.new_workers_count = 0;
                this.newMailsIds.worker_id = 0;
                break;
            case 'offers':
                this.newMailsCounts.new_offers_count = 0;
                this.newMailsIds.offer_id = 0;
                break;
            default:
                break;
        }
        this.updateData(this.newMailsCounts, this.newMailsIds);
    };
    NewReceivedMailService.ngInjectableDef = i0.defineInjectable({ factory: function NewReceivedMailService_Factory() { return new NewReceivedMailService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient), i0.inject(i3.AdminMessageService)); }, token: NewReceivedMailService, providedIn: "root" });
    return NewReceivedMailService;
}(HttpClientAbstract));
export { NewReceivedMailService };
