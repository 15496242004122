import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-component',
  templateUrl: './privacy-policy-component.component.html',
  styleUrls: [
    './privacy-policy-component.component.scss',
    '../../shared/style/service-rule.scss'
  ]
})
export class PrivacyPolicyComponentComponent implements OnInit {
  sessionStorage: object;

  constructor() {}

  ngOnInit() {
    this.sessionStorage = sessionStorage;
  }

  isLogin() {
    return !!localStorage.account;
  }
}
