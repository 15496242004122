import { HttpClient } from '@angular/common/http';
import { API_SERVER_URL } from '../../../environments/environment';
import { finalize } from 'rxjs/operators';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { AccountService } from 'src/app/shared/service/account.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./account.service";
var SessionService = /** @class */ (function () {
    function SessionService(header, http, accountService) {
        this.header = header;
        this.http = http;
        this.accountService = accountService;
    }
    SessionService.prototype.signIn = function (loginForm) {
        var url = API_SERVER_URL + 'api/v1/auth/sign_in';
        return this.http.post(url, loginForm, this.header.getHttpOptions());
    };
    SessionService.prototype.signOut = function () {
        var _this = this;
        var url = API_SERVER_URL + 'api/v1/auth/sign_out';
        return this.http
            .delete(url, this.header.getHttpOptions())
            .pipe(finalize(function () { return _this.accountService.clear(); }));
    };
    SessionService.prototype.passwordResetMail = function (email) {
        var url = API_SERVER_URL + 'api/v1/password/reset_mail';
        return this.http.post(url, email, this.header.getHttpOptions());
    };
    SessionService.prototype.passwordResetChange = function (password) {
        var url = API_SERVER_URL + 'api/v1/password/reset_change';
        return this.http.post(url, password, this.header.getHttpOptions());
    };
    SessionService.prototype.passwordUpdateChange = function (password) {
        var url = API_SERVER_URL + 'api/v1/password/update_change';
        return this.http.post(url, password, this.header.getHttpOptions());
    };
    SessionService.prototype.verifyOtp = function (param) {
        var url = API_SERVER_URL + 'api/v1/auth/verify_otp';
        return this.http.post(url, param, this.header.getHttpOptions());
    };
    SessionService.prototype.registerBrowser = function (param) {
        var url = API_SERVER_URL + 'api/v1/auth/register_browser';
        return this.http.post(url, param, this.header.getHttpOptions());
    };
    SessionService.prototype.deleteBrowser = function (id, param) {
        var url = API_SERVER_URL + ("api/v1/auth/delete_browser/" + id);
        return this.http.delete(url, this.header.getHttpOptions(param));
    };
    SessionService.ngInjectableDef = i0.defineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient), i0.inject(i3.AccountService)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
