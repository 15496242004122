import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClientAbstract } from './http-client-abstract';
import { MailInfo } from '../model/mail-info.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends HttpClientAbstract {
  constructor(header: HttpHeaderService, http: HttpClient) {
    super(header, http);
  }
  // orginからdestまでの経路のGoogleMAPで表示
  public openMap(
    workerMail: MailInfo,
    projectMail: MailInfo,
  ): void {
    if (workerMail.work_location && projectMail.work_location) {
      // GoogleMapページ
      window.open(
        `https://www.google.co.jp/maps/dir/?api=1&origin=${workerMail.work_location}&destination=${projectMail.work_location}&travelmode=transit`,
        '_blank'
      );
    }
  }
}
