import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaderService } from './http-header.service';
import { Observable } from 'rxjs';
import { API_SERVER_URL } from 'src/environments/environment';

export abstract class HttpClientAbstract {
  constructor(private header: HttpHeaderService, private http: HttpClient) {}

  requestGet(requestDestination: string, params?: any): Observable<any> {
    const url = API_SERVER_URL + requestDestination;
    return this.http.get<any>(url, this.header.getHttpOptions(params));
  }
  requestGetfile(requestDestination: string, params?: any): Observable<any> {
    const url = API_SERVER_URL + requestDestination;
    return this.http.get<any>(
      url,
      Object.assign(this.header.getHttpOptions(params), {
        responseType: 'blob'
      })
    );
  }
  requestGetAssetsFile(requestDestination: string, params?: any, options?: object): Observable<any> {
    const url = 'assets/' + requestDestination;
    return this.http.get<any>(url, Object.assign(this.header.getHttpOptions(params), options));
  }

  requestPostForm(requestDestination: string, form: any): Observable<any> {
    const url = API_SERVER_URL + requestDestination;
    return this.http.post<any>(url, form, this.header.getHttpOptionsFormData());
  }
  requestPost(requestDestination: string, params?: object): Observable<any> {
    const url = API_SERVER_URL + requestDestination;
    return this.http.post<any>(url, params, this.header.getHttpOptions());
  }
  requestPatch(requestDestination: string, params?: object): Observable<any> {
    const url = API_SERVER_URL + requestDestination;
    return this.http.patch<any>(url, params, this.header.getHttpOptions());
  }
  requestDelete(requestDestination: string, params?: object): Observable<any> {
    const url = API_SERVER_URL + requestDestination;
    return this.http.delete<any>(url, this.header.getHttpOptions(params));
  }
}
