<div *ngIf="!isLogin()">
  <app-before-login-logo></app-before-login-logo>
</div>
<div [ngClass]="isLogin() ? 'after-login-background' : 'before-login-background'">
  <div class="container" style="margin-top:130px;">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="rule__text-area" [ngClass]="isLogin() ? 'after-login' : 'before-login'">
          <h2>プライバシーポリシー</h2>
          <div class="rule__text-area__border"></div>

          <p>
            リフ株式会社（以下「当社」といいます。）は、当社の提供する「Qoala（コアラ）」（以下「本サービス」といいます。）における、導入企業から受け取るその企業の本サービス管理担当者（以下「管理者ユーザー」といいます。）や、その企業の従業員である本サービスの利用者（以下「一般ユーザー」といいます。なお、管理者ユーザーと一般ユーザーを合わせて「ユーザー」といいます。）についての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。<br /><br />

            <span class="heading">第１条 法令等の遵守<br /></span>
            当社は、個人情報保護の実現のため、個人情報保護法、各省庁ガイドラインその他関連する法令等を遵守いたします。<br /><br />

            <span class="heading">第２条　収集する利用者情報及び収集方法<br /></span>
            本ポリシーにおいて、「利用者情報」とは、お申し込み時における管理者等の情報、ユーザーの識別に係る情報、通信サービス上の行動履歴、ユーザーが送受信するメールの情報、その他ユーザーのスマートフォン、パソコン等の端末においてユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が適正に収集するものをいいます。<br />
            本サービスにおいて当社が収集する利用者情報は、具体的には、以下のとおりです。<br /><br />

            １．導入企業が本サービスの利用を申し込む時にご提供いただく情報<br />
            ・　企業名称<br />
            ・　郵便番号<br />
            ・　所在地<br />
            ・　所属組織名称<br />
            ・　契約担当者氏名（企業の代表者、もしくは事業や組織の責任者）<br />
            ・　管理者ユーザー氏名<br />
            ・　管理者ユーザー電話番号<br />
            ・　管理者ユーザーFAX番号<br />
            ・　ユーザーのメールアドレス<br />
            ・　その他当社が定めるユーザー情報<br /><br />

            ２．ユーザーが本サービスを利用するにあたって、当社が収集する情報<br />
            ・　ログ情報<br />
            本サービスでは、お客様の動向を調査する為にアクセスログを取得します。取得したアクセスログ情報は、個人情報と関連づけることはなく、統計情報としてのみ使用しています。<br /><br />

            ３．本サービスのシステムやデータベースに保存、蓄積される情報<br />
            ユーザーが本サービスにメールを送信（転送を含む）し、システムの機能を利用するために入力項目に入力をするなどにより、本サービスのデータベースに一定の情報が保存、蓄積されます。その情報は以下のとおりです。<br />
            ・　メール情報（メールヘッダ情報等）<br />
            ・　メールの添付ファイル（スキルシート等）<br />
            ・　その他、ユーザーによる本サービスへの入力項目<br /><br />

            <span class="heading">第３条　情報の管理<br /></span>
            １．当社は、利用者情報を正確かつ最新の状態に保ち、利用者情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し利用者情報の厳重な管理を行ないます。<br />
            ２．本システムで取り扱う利用者情報は暗号化され、データベースで管理されます。データベースに蓄積されたメール等の情報は、他社のユーザーが閲覧することはできません。<br />
            ３．本システムのWebサイトはSSL暗号化通信を利用します。<br />
            ４．当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。<br /><br />

            <span class="heading">第４条　利用目的<br /></span>
            当社は、利用者情報を以下の目的で利用します。<br />
            １．本サービスの提供、維持、保護及び改善のため<br />
            ２．本サービスに関するご案内、お問い合せ等への対応のため<br />
            ３．本サービスに関する契約、規約、ポリシー等に違反する行為に対する対応のため<br />
            ４．本サービスに関する規約、ポリシー等の変更などを通知するため<br />
            ５．メールに含まれる情報を分析し、企業もしくは個人または案件もしくは人材を特定できない形式に加工した統計データを作成し、利用（第三者への提供も含みます）するため<br />
            ６．本サービス、機能改善等ユーザーに有用と思われる情報提供のための郵便物、電話、電子メール等による営業活動およびマーケティング活動のため。<br />
            ７．調査のご協力依頼や各種イベントへの参加を依頼し、その結果などを報告するため。<br />
            ８．サービスの提供に当たり利用目的を公表の上、ユーザーの同意を得た利用目的のため。<br />
            ９．上記の利用目的に付随する利用目的のため<br /><br />

            <span class="heading">第５条　利用者情報利用目的の変更<br /></span>
            当社は、利用者情報の利用目的を相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはユーザーに通知又は公表します。<br /><br />

            <span class="heading">第６条　個人情報の定義<br /></span>
            本ポリシーにおいて、利用者情報のうち、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）を意味するものとします。<br /><br />

            <span class="heading">第７条　個人情報利用の制限<br /></span>
            当社は、個人情報保護法その他の法令により許容される場合を除き、ユーザーの同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。<br />
            (1)　法令に基づく場合。<br />
            (2)　人の生命、身体又は財産の保護のために必要がある場合であって、ユーザーの同意を得ることが困難であるとき。<br />
            (3)　公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ユーザーの同意を得ることが困難であるとき。<br />
            (4)　国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。<br /><br />

            <span class="heading">第８条　個人情報の第三者提供<br /></span>
            当社は、個人情報ついては、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめユーザーの同意を得ないで、第三者に提供しません。但し、次に掲げる場合はこの限りではありません。<br />
            １．当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合<br />
            なお、委託先への個人情報の提供が必要になった際には、委託先に対し情報管理に関する適切な監督を行ったうえで提供を行います。<br />
            ２．合併その他の事由による事業の承継に伴って個人情報が提供される場合<br />
            ３．国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合<br />
            ４．第三者との紛争解決のため、
            又は当社が運営するサービスのユーザーもしくは第三者の権利や利益等を守るために情報開示が必要と当社が判断した場合。<br />
            ５．当社が運営するサービスの利用状況等を調査・分析するため、当社がかかる調査・分析等を委託する第三者に開示する場合。<br />
            ６．当社の広告の配信業務に関わる第三者に、利用者にどのような広告や情報、サービスなどを掲載または提供するのが効果的であるかを判断させるために履歴情報及びcookie情報、広告識別子を提供する場合。<br />
            ７．その他、個人情報保護法その他の法令で認められる場合<br /><br />

            <span class="heading">第９条　個人情報の開示<br /></span>
            当社は、ユーザーから、個人情報保護法に基づき、個人情報の開示を求められたときは、そのユーザー本人からの請求であることを確認の上で、そのユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。<br /><br />

            <span class="heading">第１０条　個人情報の訂正及び利用停止等<br /></span>
            １．当社は、ユーザーから、個人法保護法に基づき、⑴個人情報が真実でないという理由によりその内容の訂正を求められた場合、及び⑵あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、その利用の停止を求められたときは、ユーザー本人からの請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、合理的な理由に基づいて訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。但し、個人情報保護法その他の法令により、当社が利用停止等の義務を負わない場合は、この限りではありません。<br />
            ２．当社は、ユーザーから、個人情報保護法に基づき、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザー本人からの請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。但し、個人情報保護法その他の法令により、当社が利用停止等の義務を負わない場合は、この限りではありません。<br /><br />

            <span class="heading">第１１条　利用契約終了後の扱い<br /></span>
            １．ユーザーは、利用契約を終了した場合、本サービスの一部または全てが利用できなくなります。<br />
            ２．ユーザーの利用登録の削除による本サービスの利用停止等があったとしても、その時点までに当社が取得した各種情報について、当社は引き続き保存・利用できるものとします。<br /><br />

            <span class="heading">第１２条　Cookie（クッキー）その他の技術の利用<br /></span>
            本サービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当社による本サービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、本サービスの一部の機能をご利用いただけなくなる場合があります。<br /><br />

            <span class="heading">第１３条　お問い合わせ窓口<br /></span>
            個人情報の開示、訂正、利用停止、消去のお申し出、本サービスに関するご意見、ご質問、苦情その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。<br /><br />
            リフ株式会社　Qoalaサポートデスク<br />
            E-mail：info@qoala.jp（なお、受付時間は、平日10時から18時までとさせていただきます。）<br /><br />

            <span class="heading">第１４条　プライバシーポリシーの変更手続<br /></span>
            １．当社は、利用者情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーを変更することがあります。<br />
            ２．変更した場合には、メールもしくは本サービスサイトに掲載する方法でユーザーに通知いたします。本ポリシーの変更内容は、本サービスサイトに掲載された時点から変更の効力が生じます。変更の効力が生じた後、本サービスを利用したユーザーは、本規約の変更内容に同意したものとみなします。<br />
            ３．前２項の規定にかかわらず、法令上、ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法でユーザーの同意を得るものとします。<br /><br />

            2019年1月1日制定<br />
            2019年8月5日改定<br />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>