export class SearchCount {
  // TODO: 各所で設定しているが全て未使用の可能性あり
  public startCount: number;
  public endCount: number;
  public totalCount: number;
  public perPage: number;

  constructor(hash: any = {}) {
    if (Object.keys(hash).length) {
      this.startCount = hash.startCount;
      this.endCount = hash.endCount;
      this.totalCount = hash.totalCount;
      this.perPage = hash.perPage;
    }
  }

  // API送信用ハッシュを作成（変数名をスネークケース化）
  getParams() {
    return {
      // start_count: this.startCount,
      // end_count: this.endCount,
      // total_count: this.totalCount,
      per_page: this.perPage
    };
  }
}
