<div class="footer-basic footer-style" *ngIf="!isLogin()">
  <footer>
    <div class="social"></div>
    <ul class="list-inline">
      <li class="list-inline-item">
        <a (click)="clickTermOfService()" style="cursor: pointer">利用規約</a>
      </li>
      <li class="list-inline-item">
        <a (click)="clickPrivacyPolicy()" style="cursor: pointer"
          >プライパシーポリシー</a
        >
      </li>
      <li class="list-inline-item">
        <a target="_blank" href="https://lp.qoala.jp/">Qoalaについて</a>
      </li>
      <li class="list-inline-item">
        <a target="_blank" href="https://www.riff.co.jp/">運営会社について</a>
      </li>
      <li class="list-inline-item">
        <a target="_blank" href="https://qoala.jp/contact/">お問い合わせ</a>
      </li>
    </ul>
    <p class="copyright copyright-style">
      Ⓒ Riff,Inc. All Right Reserved.
    </p>
  </footer>
</div>
