import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute
} from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private route: ActivatedRoute) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.account) {
      // logged in so return true
      return true;
      // this.router.navigate(['/matching/projects']);
      // return false;
    }
    // 暫定コード。
    if (window.location.pathname === '/account/password/reset/mail') {
      return true;
    }

    if (window.location.pathname === '/account/password/reset/change') {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
