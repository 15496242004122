import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminMessage } from 'src/app/shared/model/admin-message.model';
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AdminMessageService } from 'src/app/shared/service/admin-message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-message-publisher',
  templateUrl: './admin-message-publisher.component.html',
  styleUrls: ['./admin-message-publisher.component.scss']
})
export class AdminMessagePublisherComponent implements OnInit, OnDestroy {
  newMessage = this.newForm();

  deleteMessageId = new FormControl(null, Validators.required);

  messages: AdminMessage[] = [];
  private subscriptions: Subscription[] = [];

  constructor(private adminMessageService: AdminMessageService) {}

  ngOnInit() {
    const adminMessageSub = this.adminMessageService.adminMessages$.subscribe(
      adminMessages => (this.messages = adminMessages.messages)
    );
    this.subscriptions.push(adminMessageSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  newForm() {
    return new FormGroup({
      message: new FormControl('', Validators.required),
      url: new FormControl(''),
      level: new FormControl(0),
      start_at: new FormControl(null, Validators.required),
      expire_at: new FormControl(null, Validators.required)
    });
  }

  onSubmit() {
    const createMessageSub = this.adminMessageService
      .createNewMessage(this.newMessage.value)
      .subscribe(res => {
        this.newMessage = this.newForm();
        this.adminMessageService.updateMessages();
      });
    this.subscriptions.push(createMessageSub);
  }

  deleteMessage() {
    console.log(this.deleteMessageId.value);
    const deleteMessageSub = this.adminMessageService
      .deleteMessage(this.deleteMessageId.value)
      .subscribe(res => {
        this.deleteMessageId.reset();
        this.adminMessageService.updateMessages();
      });
    this.subscriptions.push(deleteMessageSub);
  }
}
