import { Injectable } from '@angular/core';
import { HttpClientAbstract } from './http-client-abstract';
import { HttpHeaderService } from './http-header.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmMailTemplateService extends HttpClientAbstract {
  constructor(header: HttpHeaderService, http: HttpClient) {
    super(header, http);
  }

  public getMailTemplate(): Observable<any> {
    return super.requestGet('api/v1/confirm_mail_template');
  }

  public activateMailTemplate(isActivated: boolean): Observable<any> {
    const params = { activate: isActivated };
    return super.requestPost('api/v1/confirm_mail_template/activate', params);
  }

  public saveMailTemplate(template: string): Observable<any> {
    const params = { mail_template: template };
    return super.requestPost('api/v1/confirm_mail_template/create', params);
  }
}
