import { Pipe, PipeTransform } from '@angular/core';
const common_props = {
  keyword: 'キーワード',
  fee_from: '金額下限',
  fee_to: '金額上限',
  fee_null: '金額不明',
  age_from: '年齢下限',
  age_to: '年齢上限',
  age_null: '年齢不明',
  number_of_connect_company: '商深',
  number_of_connect_company_null: '商深不明',
  interview_times: '面談',
  interview_times_null: '面談不明',
  work_location: '場所',
  work_immidiately: '開始',
  work_immidiately_null: '開始不明',
  commute_location: '時間(駅名)',
  commute_time: '時間(分)',
  bp_rank: 'BPランク',
  mail_from: '送信元',
  receipt_datetime: '受信日',
  begin_from: '開始From',
  begin_null: '開始不明',
  begin_to: '開始To',
  required_skills: 'スキル検索',
  gross_margin_setting: '粗利',
  mail_custom_tag_ids: '自作タグids',
  mail_custom_tag_names: '自作タグ'
};

const project_props = Object.assign(
  {
    country_of_citizenship: '外国籍',
    country_of_citizenship_null: '外国籍不明',
    employment_status: '個人',
    employment_status_null: '個人不明'
  },
  common_props
);
const worker_props = Object.assign(
  {
    country_of_citizenship: '国籍',
    country_of_citizenship_null: '国籍不明',
    employment_status: '雇用',
    employment_status_null: '雇用不明'
  },
  common_props
);

@Pipe({
  name: 'translateSearchCondition'
})
export class TranslateSearchConditionPipe implements PipeTransform {
  transform(prop: string, value: string, mailType: string): string {
    const japanesePropName = this.translateProp(prop, mailType) || prop;
    value = this.translateValue(prop, value, mailType);
    return japanesePropName + '： ' + value;
  }

  private translateProp(prop: string, mailType: string): string {
    if (mailType === 'project') {
      return project_props[prop];
    } else {
      return worker_props[prop];
    }
  }

  private translateValue(
    prop: string,
    value: string | boolean,
    mailType: string
  ): string {
    if (value === 'false' || value === false) {
      value = '非表示';
    } else if (value === 'true' || value === true) {
      value = '表示';
    }
    switch (prop) {
      case 'employment_status':
        if (mailType === 'project') {
          if (value === '1') {
            value = 'NG';
          } else {
            value = 'OK';
          }
        } else {
          if (value === '1') {
            value = '社員';
          } else {
            value = '個人';
          }
        }
        break;
      case 'country_of_citizenship':
        if (mailType === 'project') {
          if (value === '1') {
            value = 'OK';
          } else {
            value = 'NG';
          }
        } else {
          if (value === '1') {
            value = '日本';
          } else {
            value = '外国';
          }
        }
        break;
      default:
        value = value;
    }
    return value;
  }
}
