import { Injectable, OnDestroy } from '@angular/core';
import { SERVICE_URL } from 'src/environments/environment';
import { API_SERVER_URL } from 'src/environments/environment';
import { MailQuotesPipe } from '../pipe/mail-quotes.pipe';
import { LeaveOnlyImportantLinePipe } from '../pipe/leave-only-important-line.pipe';
import { ConfirmMailTemplateService } from './confirm-mail-template.service';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrepareTextService implements OnDestroy {
  bodySubject: Subject<string> = new Subject();
  private subscriptions: Subscription = new Subscription();

  constructor(
    private mailQuotesPipe: MailQuotesPipe,
    private leaveOnlyImportantLinePipe: LeaveOnlyImportantLinePipe,
    private confirmMailTemplateService: ConfirmMailTemplateService
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  // ユーザのメール定型文設定リクエストの取得後にメール本文を生成するため、戻り値はsubject
  setApproveMailText(res, offerType: string): Subject<string> {
    // ユーザのメール定型文設定の取得
    const mailTemplateSub = this.confirmMailTemplateService.getMailTemplate().subscribe(result => {
      let body = '';
      const mailTemplate = result.body.mail_template;
      const isMailTemplateActivated = result.body.is_activated;
      body = this.confirmMailBody(
        res,
        offerType,
        mailTemplate,
        isMailTemplateActivated
      );
      this.bodySubject.next(body);
    });
    this.subscriptions.add(mailTemplateSub);
    return this.bodySubject;
  }

  private confirmMailBody(
    res,
    offerType: string,
    mailTemplate: string,
    isMailTemplateActivated: boolean
  ): string {
    let body = '';
    const project_mail = res.body.project_mail;
    const worker_mail = res.body.worker_mail;
    const downloadUrlPrefix = API_SERVER_URL + 'download/file_request?key=';
    // 承諾・辞退入力画面URL
    const approve_confirmation_response_page_url = `${SERVICE_URL}approve-confirmation-response?token=${res.body.auth_info.token}`;

    let base_mail = worker_mail;
    let other_mail = project_mail;
    if (offerType === 'worker') {
      base_mail = project_mail;
      other_mail = worker_mail;
    }

    if (isMailTemplateActivated) {
      body = mailTemplate;
    } else {
      body = this.defaultOfferTemplate();
    }
    body += '\n\n■ 回答用URL\n';
    body += approve_confirmation_response_page_url;
    body += '\n\n\n';
    body += `■ ${
      offerType === 'worker' ? '提案案件' : '紹介人材'
    } ===================`;
    body += '\n\n';
    if (base_mail.attached_files[0]) {
      if (base_mail.attached_files[0].name !== 'skill_sheets.zip') {
        // file_info_urlをキーにしてAPIサーバー経由でS3ファイルをダウンロードするURLを作成
        body += `スキルシート: ${downloadUrlPrefix}${base_mail.attached_files[0].file_info_url}`;
      }
    }
    body += '\n\n';
    body += this.leaveOnlyImportantLinePipe.transform(
      base_mail.body,
      base_mail
    );

    body += '\n\n\nOriginal Mail\n';
    body += this.mailQuotesPipe.transform(other_mail.body);

    return body;
  }

  private defaultOfferTemplate(): string {
    let body: string;
    body = 'お世話になっております。';
    body += '\nいただいた表題のメールに関しまして、以下の提案をさせていただきます。';
    body += '\nご検討のほど、どうぞよろしくお願いいたします。';
    body += '\n（以下のURLリンクをクリックいただくと、システム上でご回答を入力いただけます）';
    return body;
  }

  setReplyMailText(mailBody: string): string {
    // ユーザのメール定型文設定の取得

    let body = '';
    body += '\n\n\nOriginal Mail\n';
    body += this.mailQuotesPipe.transform(mailBody);

    return body;
  }
}
