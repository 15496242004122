import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
// 文字列の変換を行うサービスクラス
export class StringService {

  constructor() { }

  // 全角英文字 -> 半角英文字
  zenkakuToHankaku(str: string): string {
    return this.zenakuMinusToHankakuDash(str).replace(
      /[Ａ-Ｚａ-ｚ０-９．！-／：-＠［-｀｛-～、-〜”’・]/g,
      function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      }
    );
  }

  // かな -> カナに変換
  hiraToKana(str: string): string {
    return str.replace(/[\u3041-\u3096]/g, function(s) {
      let chr = s.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  }

  // 全角のマイナス -> 半角ダッシュ
  zenakuMinusToHankakuDash(str: string): string {
    return str.replace('−', '-');
  }

  // 半角スペースを空白文字に変更
  replaceHalfWidthSpace(str: string): string {
    return str.replace(new RegExp(' ', 'g'), '&ensp;');
  }

  //  半角英文字 -> 全角英文字
  hankakuToZenkaku(str: string) {
    return str.replace(/[A-Za-z0-9]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
    });
  }

  // 特殊文字のエスケープ
  escapeSpecialChar(str: string) {
    return str.replace(/[\\\/:*?+"<>|\^$.()\[\]{}]/g, function(ch) {
      // 特殊文字の前にバックスラッシュを付与し単に文字列として扱う
      return '\\' + ch;
    });
  }

  // メール文の文字デザインで、変更が不要なキーワードかどうかを判定
  unnecessaryKey(string: string): boolean {
    const unnecessaryKeys = [
      'FW',
      'エンジニア',
      'OS',
      'サービス',
      'システム',
      'プロジェクト',
      'スキル',
      '開発'
    ];
    return unnecessaryKeys.includes(string);
  }

  // 末尾のハイフンを削除
  deleteEndHyphen(str: string): string {
    const lastCharacter = str.slice(-1);
    // 末尾の文字がハイフン(全角・半角問わず)の場合
    if ((lastCharacter === 'ｰ') || (lastCharacter === 'ー')) {
      // 末尾の文字を削除
      str = str.slice(0, -1);
    }
    return str;
  }

  // 半角大文字に変換
  zenkakuToHankakuAndUppercase(str: string): string {
    return this.zenkakuToHankaku(str).toUpperCase();
  }

  // スキル検索・ハイライトに用いるテキスト平準化関数
  textFormatForSkillSearch(str: string): string {
    // 半角大文字に変換
     str = this.zenkakuToHankakuAndUppercase(str)
    // かな -> カナに変換
     str = this.hiraToKana(str)
    // スペースを削除
     str = this.replaceHalfWidthSpace(str)
    // 末尾のハイフンを削除
     str = this.deleteEndHyphen(str)
    return str;
  }

  // 配列内すべての文字列をスキル検索・ハイライト用にフォーマット
  allInArrayTextFormatForSkillSearch(array: Array<string>): Array<string> {
    return array.map( element =>
      this.textFormatForSkillSearch(element)
    )
  }

  // スキル配列に対して、対象キーワードが含まれていれば、ハイライト対象としてtrueを返す
  isHighlightTerget(skills: Array<string>, keyword: string): boolean {
    const uppercaseSkills = this.allInArrayTextFormatForSkillSearch(skills)
    const uppercaseKeyword = this.textFormatForSkillSearch(keyword)

    // skills、keywordどちらも半角大文字に変換して判定
    return uppercaseSkills.includes(uppercaseKeyword)
  }

  // title_json/body_json内の、条件に該当するキーワードに対し装飾classを付与する関数
  checkKeywordDecoration(
    matchedSkills: Array<string>,
    baseKeywords: Array<string>,
    vocabularyWord: string,
    meaningWord: string,
    importanceLevel: number
  ): string {
    // jsonの重要度が2以上の場合かつ、不要キーワードに該当しない場合は、jsonの単語名を太字化する
    if (
      (importanceLevel >= 2) && (this.unnecessaryKey(vocabularyWord)  === false)
    ) {
      const replactTarget = this.replaceHalfWidthSpace(vocabularyWord)
      const formattedMeaningWord = this.textFormatForSkillSearch(meaningWord)

      // baseKeywordsを半角大文字に寄せる
      const formattedBaseKeywords = this.allInArrayTextFormatForSkillSearch(baseKeywords)
      const matched = formattedBaseKeywords.includes(formattedMeaningWord) ? 'matched' : ''
      let searched = ''

      if (matchedSkills) {
        // jsonの意味語とスキル検索のキーワードが一致する場合は、spanタグにhighlightクラスを付与する
        // 例: 検索語句が「リモート」の場合、表示されている文字が「テレワーク」でも意味語が「リモート」の場合はハイライトする
        this.isHighlightTerget(matchedSkills, meaningWord) ? searched = 'highlight' : ''
      }

      vocabularyWord = `<span class="strong ${matched} ${searched}">${replactTarget}</span>`
    }

    // 装飾classの付与に該当しないキーワードの場合はそのまま返す
    return vocabularyWord
  }

  // htmlタグとして認識される < > をエスケープするための関数
  escapeHTMLTags(str: string): string {
    if (str[0] === '<') return '&lt';
    if (str[0] === '>') return '&gt';
    return str[0];
  }
}
