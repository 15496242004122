import { Component } from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import { DateAdapter } from "@angular/material/core";

@Component({
  selector: 'app-calender-custom-header',
  templateUrl: './calender-custom-header.component.html',
  styleUrls: ['./calender-custom-header.component.scss']
})
export class CalenderCustomHeaderComponent {
  constructor(
    private _calendar: MatCalendar<any>,
    private _dateAdapter: DateAdapter<any>
  ) {}

  get periodLabel() {
    const activeDate = this._calendar.activeDate;
    // JavaScriptの月は0から始まるので+1する
    return `${activeDate.getFullYear()}/${activeDate.getMonth() + 1 < 10 ? '0' : ''}${activeDate.getMonth() + 1}`;
  }

  // 前の月を表示
  previousClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }

  // 次の月を表示
  nextClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }
}
