import { AccountService } from 'src/app/shared/service/account.service';
import { Account } from '../model/account.model';
import { APP_KEY } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpParameterCodec } from '@angular/common/http/src/params';

@Injectable({
  providedIn: 'root'
})
export class HttpHeaderService {
  constructor(private accountService: AccountService) {}

  getHeader(account: Account, contentType: 'json' | 'dataForm' = 'json') {
    const params = {
      'X-App-Key': APP_KEY,
      Authorization: account.accessToken,
      client: account.client,
      uid: account.uid,
      'access-token': account.accessToken // Devise対応
    };

    if (contentType === 'json') {
      Object.assign(params, { 'Content-Type': 'application/json' });
    }
    return new HttpHeaders(params);
  }
  getHttpOptions(params?: any): any {
    const account = this.accountService.getAccount();
    // HTTPヘッダ情報
    const header = this.getHeader(account);
    // HTTPボディパラメータ
    const paramsOptions = { fromObject: params, encoder: new CustomEncoder() };
    return {
      headers: header,
      params: new HttpParams(paramsOptions),
      observe: 'response'
    };
  }

  getHttpOptionsFormData(params?: any): any {
    const account = this.accountService.getAccount();
    // HTTPヘッダ情報
    const header = this.getHeader(account, 'dataForm');
    // HTTPボディパラメータ
    const paramsOptions = { fromObject: params, encoder: new CustomEncoder() };
    return {
      headers: header,
      params: new HttpParams(paramsOptions),
      observe: 'response'
    };
  }

  getSTGHttpOptions(params?: any): any {
    const account = this.accountService.getSTGAccount();
    // HTTPヘッダ情報
    const header = this.getHeader(account);

    // HTTPボディパラメータ
    const paramsOptions = { fromObject: params, encoder: new CustomEncoder() };
    return {
      headers: header,
      params: new HttpParams(paramsOptions),
      observe: 'response'
    };
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
