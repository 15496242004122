import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'app-tag-button',
  templateUrl: './tag-button.component.html',
  styleUrls: ['./tag-button.component.scss']
})
export class TagButtonComponent implements OnInit {
  @Input() tagName: string;
  @Input() toolTipTemplate: TemplateRef<any>;
  @Input() activated = false;
  @Input() buttonMode = false;
  @Input() color = 'green';

  @Output() tagClick = new EventEmitter<boolean>();
  @Output() tagDelete = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}
}
