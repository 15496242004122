import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modifyEmploymentStatusTextForProject'
})
export class ModifyEmploymentStatusTextForProjectPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 'direct_agreement':
      case 'contract_employee':
        return 'NG';
      case 'solo_worker':
      case 'freelance':
        return 'OK';
      default:
        return null;
    }
  }

  transformForCondition(value: any, args?: any): any {
    switch (value) {
      case 'direct_agreement':
      case 'contract_employee':
        return '1';
      default:
        return null;
    }
  }
}
