<div class="tag-container" [ngClass]="{'activated-tag': activated}" *ngIf="buttonMode; else labelMode">
  <span class="badge tag del-part" [ngClass]="{'user-tag': color==='green','search-tag': color==='blue', 'qoala-tag': color==='red'}"
    (click)="tagDelete.emit()">&times;</span>
  <span class="badge tag body-part" [ngClass]="{'user-tag': color==='green','search-tag': color==='blue', 'qoala-tag': color==='red'}"
    (click)="tagClick.emit(activated)">
    {{tagName}}
  </span>
</div>

<ng-template #labelMode>
  <div class="tag-container" (click)="tagClick.emit(activated)">
    <span class="badge tag"
      [ngClass]="{'activated-tag': activated,'qoala-light-green': color==='green','qoala-light-blue': color==='blue','qoala-light-pink': color==='pink','qoala-tag': color==='red'}">
      {{tagName}}
      <ng-content></ng-content>
    </span>
  </div>
</ng-template>