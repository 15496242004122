<div class="form_card form_card_large">
  <form [formGroup]="newMessage" (ngSubmit)="onSubmit()">
    <label>
      message:
      <textarea formControlName="message"></textarea>
      <!-- <input type="text" formControlName="message" /> -->
    </label>

    <label>
      URL:
      <input
        type="text"
        formControlName="url"
        placeholder="「https://」を含めてコピペしてください"
      />
    </label>
    <label>
      種類:
      <select formControlName="level">
        <option type="number" value="0" selected>普通情報</option>
        <option type="number" value="1">アンケート</option>
        <option type="number" value="2">注意事項</option>
      </select>
    </label>
    <label>
      開始日時:
      <input type="datetime-local" formControlName="start_at" />
    </label>
    <label>
      終了日時:
      <input type="datetime-local" formControlName="expire_at" />
    </label>
    <button
      [ngStyle]="{ opacity: newMessage.valid ? 1 : 0.2 }"
      type="submit"
      [disabled]="!newMessage.valid"
    >
      発表
    </button>
  </form>
</div>

<div class="form_card form_card_large">
  <form (ngSubmit)="deleteMessage()">
    <label>
      active通知:
      <select style="max-width: 35rem;" [formControl]="deleteMessageId">
        <option type="number" value="null" selected>---</option>
        <option
          type="number"
          *ngFor="let message of messages"
          [value]="message.id"
          >{{ message.message }}</option
        >
      </select>
    </label>

    <button
      [ngStyle]="{ opacity: deleteMessageId.valid ? 1 : 0.2 }"
      type="submit"
      [disabled]="!deleteMessageId.valid"
    >
      削除
    </button>
  </form>
</div>
