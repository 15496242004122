import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import {
  NewReceivedMails,
  NewMailsIds,
  NewMailsCounts
} from '../model/new-received-mails';
import { AdminMessageService } from './admin-message.service';

@Injectable({
  providedIn: 'root'
})
export class NewReceivedMailService extends HttpClientAbstract implements OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(
    header: HttpHeaderService,
    http: HttpClient,
    adminMessageService: AdminMessageService
  ) {
    super(header, http);
    const adminMessageSub = adminMessageService.newReceivedCount$.subscribe(res => {
      if (res) {
        this.updateData(res.counts, res.ids);
      }
    });
    this.subscriptions.add(adminMessageSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public newReceivedNumber = new Subject<NewMailsCounts>();
  public newReceivedState = this.newReceivedNumber.asObservable();

  public newMailsIds: NewMailsIds = new NewMailsIds(
    JSON.parse(localStorage.getItem('message_count_ids')) || {}
  );

  public newMailsCounts: NewMailsCounts = new NewMailsCounts({});

  private updateData(newMailsCounts, newMailsIds): void {
    this.newMailsCounts = newMailsCounts;
    this.newMailsIds = newMailsIds;
    localStorage.setItem('message_count_ids', JSON.stringify(this.newMailsIds));
    this.newReceivedNumber.next(this.newMailsCounts);
  }

  public initCount(name: string): void {
    switch (name) {
      case 'projects':
        this.newMailsCounts.new_projects_count = 0;
        this.newMailsIds.project_id = 0;
        break;
      case 'workers':
        this.newMailsCounts.new_workers_count = 0;
        this.newMailsIds.worker_id = 0;
        break;
      case 'offers':
        this.newMailsCounts.new_offers_count = 0;
        this.newMailsIds.offer_id = 0;
        break;

      default:
        break;
    }
    this.updateData(this.newMailsCounts, this.newMailsIds);
  }
}
