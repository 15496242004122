import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClickEventService {
  constructor() {}

  public projectTabSubject = new Subject<any>();
  public projectTabState = this.projectTabSubject.asObservable();

  public workerTabSubject = new Subject<any>();
  public workerTabState = this.workerTabSubject.asObservable();

  public autoTabSubject = new Subject<any>();
  public autoTabState = this.autoTabSubject.asObservable();

  public offerTabSubject = new Subject<any>();
  public offerTabState = this.offerTabSubject.asObservable();
}
