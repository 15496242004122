import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/shared/service/account.service';

@Component({
  selector: 'app-before-login-logo',
  templateUrl: './before-login-logo.component.html',
  styleUrls: ['./before-login-logo.component.scss']
})
export class BeforeLoginLogoComponent implements OnInit {
  constructor(public accountService: AccountService) {}

  ngOnInit() {}

  logoPath(): string {
    return this.accountService.themeMode
      ? 'assets/img/logo.png'
      : 'assets/img/logo_dark.svg';
  }
}
