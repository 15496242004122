import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateCountryOfCitizenshipText'
})
export class TranslateCountryOfCitizenshipTextPipe implements PipeTransform {
  transform(value: any): any {
    if (value === 'JAP') {
      return 'JPN';
    } else if (value === 'XXX') {
      return '外国';
    } else if (value === 'ANY') {
      return 'その他';
    } else if (!!value === false) {
      // 日本の場合。
      return '-';
    } else {
      // 対応する国がないときはとりあえず略語を表示。
      return value;
    }
  }

  transformForCondition(value: any, searchType: string): any {
    if (searchType === 'project') {
      if (value === 'JAP' || value === 'JPN') {
        return null;
      } else {
        return '1';
      }
    } else {
      if (value === 'JAP' || value === 'JPN') {
        return '1';
      } else {
        return null;
      }
    }
  }
}
