import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends HttpClientAbstract {

  constructor(
    header: HttpHeaderService,
    http: HttpClient,
  ) {
    super(header, http)
  }

  public getReport(): Observable<any> {
    return this.requestGet('api/v1/reports')
  }
}
