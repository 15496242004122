/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tag-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tag-button.component";
var styles_TagButtonComponent = [i0.styles];
var RenderType_TagButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagButtonComponent, data: {} });
export { RenderType_TagButtonComponent as RenderType_TagButtonComponent };
function View_TagButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "tag-container"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "activated-tag": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "badge tag del-part"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tagDelete.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "user-tag": 0, "search-tag": 1, "qoala-tag": 2 }), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "span", [["class", "badge tag body-part"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tagClick.emit(_co.activated) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "user-tag": 0, "search-tag": 1, "qoala-tag": 2 }), (_l()(), i1.ɵted(10, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tag-container"; var currVal_1 = _ck(_v, 2, 0, _co.activated); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "badge tag del-part"; var currVal_3 = _ck(_v, 5, 0, (_co.color === "green"), (_co.color === "blue"), (_co.color === "red")); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = "badge tag body-part"; var currVal_5 = _ck(_v, 9, 0, (_co.color === "green"), (_co.color === "blue"), (_co.color === "red")); _ck(_v, 8, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.tagName; _ck(_v, 10, 0, currVal_6); }); }
function View_TagButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "tag-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tagClick.emit(_co.activated) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "badge tag"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "activated-tag": 0, "qoala-light-green": 1, "qoala-light-blue": 2, "qoala-light-pink": 3, "qoala-tag": 4 }), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "badge tag"; var currVal_1 = _ck(_v, 3, 0, _co.activated, (_co.color === "green"), (_co.color === "blue"), (_co.color === "pink"), (_co.color === "red")); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.tagName; _ck(_v, 4, 0, currVal_2); }); }
export function View_TagButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["labelMode", 2]], null, 0, null, View_TagButtonComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonMode; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TagButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tag-button", [], null, null, null, View_TagButtonComponent_0, RenderType_TagButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.TagButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagButtonComponentNgFactory = i1.ɵccf("app-tag-button", i3.TagButtonComponent, View_TagButtonComponent_Host_0, { tagName: "tagName", toolTipTemplate: "toolTipTemplate", activated: "activated", buttonMode: "buttonMode", color: "color" }, { tagClick: "tagClick", tagDelete: "tagDelete" }, ["*"]);
export { TagButtonComponentNgFactory as TagButtonComponentNgFactory };
