/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mailer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./mailer.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_MailerComponent = [i0.styles];
var RenderType_MailerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MailerComponent, data: {} });
export { RenderType_MailerComponent as RenderType_MailerComponent };
function View_MailerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary-dark mail-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMailer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-envelope-o"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30E1\u30FC\u30EB\u30BD\u30D5\u30C8\u3092\u8D77\u52D5 "]))], null, null); }
export function View_MailerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u9001\u4FE1\u65B9\u6CD5\u306E\u9078\u629E"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close(_co.matchingOffer) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"], ["class", "close-button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u4EE5\u4E0B\u306E\u30DC\u30BF\u30F3\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u30E1\u30FC\u30EB\u30BD\u30D5\u30C8\u3092\u7ACB\u3061\u4E0A\u3052\u3066\u304F\u3060\u3055\u3044"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MailerComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mailHref; _ck(_v, 11, 0, currVal_0); }, null); }
export function View_MailerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mailer", [], null, null, null, View_MailerComponent_0, RenderType_MailerComponent)), i1.ɵdid(1, 114688, null, 0, i3.MailerComponent, [i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailerComponentNgFactory = i1.ɵccf("app-mailer", i3.MailerComponent, View_MailerComponent_Host_0, { mailHref: "mailHref", mailTo: "mailTo", mailCc: "mailCc", mailSubject: "mailSubject", matchingOffer: "matchingOffer" }, {}, []);
export { MailerComponentNgFactory as MailerComponentNgFactory };
