import { Injectable } from '@angular/core';
import { GA_TRACK_ID } from 'src/environments/environment';
import { AccountService } from 'src/app/shared/service/account.service';

declare let gtag: Function;
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalysticsService {
  constructor(private accountService: AccountService) {
    gtag('config', GA_TRACK_ID, {
      custom_map: {
        dimension1: '案件ID',
        dimension2: '人材ID',
        dimension3: 'イベント'
      }
    });
    // ログイン済みの場合のみユーザーIDを送信する
    if (this.isLogin()) {
      gtag('config', GA_TRACK_ID, {
        user_id: this.accountService.getUid(),
      });
    }
  }

  pageView(link: string) {
    gtag('event', 'page_view', { page_path: link });
  }

  eventEmit(GAevent: string, param_1: string, param_2: string, value: number) {
    gtag('event', String(param_1), {
      event_category: String(GAevent),
      event_label: String(param_2),
      value: value
    });
    gtag('event', GAevent + 'dimension', {
      案件ID: param_1,
      人材ID: param_2,
      イベント: GAevent
    });
    //      event                       param_1      param_2
    // P列　元メールDL数　　　　　　　　　どのページで　メールID
    // Q列　スキルシートDL数　　　　　　　どのページで　メールIDのスキルシートがDL
    // R列　提案管理ボタンが押された回数　案件ID　　　　人材ID
    // S列　案件確認ボタンが押された回数　案件ID　　　　人材ID
    // T列　案件承諾に至った回数　　　　　案件ID　　　　人材ID
    // U列　人材確認ボタンが押された回数　案件ID　　　　人材ID
    // V列　人材承諾に至った回数　　　　　案件ID　　　　人材ID
  }

  isLogin() {
    return !!localStorage.account;
  }
}
